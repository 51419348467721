import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CreateLink,
  headingsPlugin,
  InsertThematicBreak,
  linkDialogPlugin,
  listsPlugin,
  ListsToggle,
  MDXEditor,
  quotePlugin,
  Separator,
  thematicBreakPlugin,
  toolbarPlugin,
} from "@mdxeditor/editor";
import { Box, Stack } from "@mui/joy";
import React from "react";
import { BoxProps } from "@mui/joy/Box";

type Props = {
  markdown: string;
  readonly?: boolean;
  onChange?: (markdown: string) => void;
} & {
  sx?: BoxProps["sx"];
};

export const MarkdownEditor: React.FC<Props> = ({ readonly, markdown, onChange, ...rest }) => {
  const borderProps = readonly
    ? {}
    : {
        border: "1px solid",
        borderColor: "divider",
        borderRadius: 7,
      };
  const boxProps = { ...rest, ...borderProps };

  return (
    <Box {...boxProps}>
      <MDXEditor
        markdown={markdown}
        onChange={!readonly ? onChange : undefined}
        readOnly={readonly}
        plugins={[
          toolbarPlugin({
            toolbarContents: () =>
              !readonly && (
                <Stack direction="row" flexWrap="wrap" divider={<Separator />}>
                  {/*<UndoRedo />*/}
                  <BoldItalicUnderlineToggles />
                  <ListsToggle />
                  <BlockTypeSelect />
                  <InsertThematicBreak />
                  <CreateLink />
                </Stack>
              ),
          }),
          headingsPlugin(),
          quotePlugin(),
          listsPlugin(),
          thematicBreakPlugin(),
          linkDialogPlugin(),
        ]}
      />
    </Box>
  );
};
