import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Sheet,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
} from "@mui/joy";

import USD from "@mui/icons-material/AttachMoney";

import { useMutation } from "@apollo/client";
import { createManualTransactionMutation } from "../mutations/create-manual-transaction";
import { createTransactionFromItemMutation } from "../mutations/create-transaction-from-item";
import { PiggyBankItem } from "../../../../../generated/gql/graphql";
import { ItemPicker } from "../ItemPicker";
import { getPiggyBankDetailsQuery } from "../queries/get-piggy-bank-details";
import { useSetPageTitle } from "../../../../../contexts/app-view";

enum TransactionType {
  Item = 0,
  Manual = 1,
}

enum ManualTransactionType {
  Deposit = "Deposit",
  Withdrawal = "Withdrawal",
  Unselected = "Unselected",
}

type Props = {
  piggyBankId: string;
  transaction?: any; // TODO: support editing transactions
};

export const AddEditTransactionForm: React.FC<Props> = ({ transaction, piggyBankId }) => {
  useSetPageTitle(transaction ? "Edit Transaction" : "Add Transaction");
  const [memo, setMemo] = useState(transaction?.memo || "");
  const [amount, setAmount] = useState(transaction?.amount.toString() || "");
  const [preventAutoTransfer, setPreventAutoTransfer] = useState(false);
  const [item, setItem] = useState<PiggyBankItem | null>(null);
  const [quantity, setQuantity] = useState<string>("1");
  const [type, setType] = useState<TransactionType>(TransactionType.Item);
  const [manualType, setManualType] = useState<ManualTransactionType>(
    ManualTransactionType.Unselected,
  );

  const [createManualTransaction] = useMutation(createManualTransactionMutation, {
    refetchQueries: [getPiggyBankDetailsQuery],
  });
  const [createTransactionFromItem] = useMutation(createTransactionFromItemMutation, {
    refetchQueries: [getPiggyBankDetailsQuery],
  });
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const parsedAmount = parseFloat(amount);
    const parsedQuantity = parseInt(quantity, 10);

    if (isNaN(parsedQuantity)) {
      alert("Quantity must be a number");
      return;
    }

    switch (type) {
      case TransactionType.Item:
        if (!item) {
          alert("Item is required");
          return;
        }

        await createTransactionFromItem({
          variables: {
            input: {
              piggyBankId,
              itemId: item.id,
              quantity: parsedQuantity,
              preventAutoTransfer,
            },
          },
        });
        break;
      case TransactionType.Manual: {
        if (isNaN(parsedAmount)) {
          alert("Amount must be a number");
          return;
        }

        if (manualType === ManualTransactionType.Unselected) {
          alert("You must select either Deposit or Withdrawal");
          return;
        }

        const amountWithDirection =
          manualType === ManualTransactionType.Deposit
            ? Math.abs(parsedAmount)
            : -Math.abs(parsedAmount);

        await createManualTransaction({
          variables: {
            input: {
              piggyBankId,
              amount: amountWithDirection,
              memo,
              preventAutoTransfer,
            },
          },
        });
        break;
      }
    }

    navigate(-1);
  };

  return (
    <Container>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ display: "flex", flexDirection: "column", gap: 2 }}
      >
        <Tabs value={type} onChange={(_, value) => setType(value as TransactionType)}>
          <TabList tabFlex={1}>
            <Tab variant="plain" color="neutral">
              Items
            </Tab>
            <Tab variant="plain" color="neutral">
              Manual
            </Tab>
          </TabList>

          <TabPanel value={TransactionType.Item}>
            <FormControl>
              <FormLabel>Job</FormLabel>
              <ItemPicker onSelect={setItem} />
            </FormControl>
            <FormControl>
              <FormLabel>Quantity</FormLabel>
              <Input
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                type="number"
                required
              />
            </FormControl>
          </TabPanel>

          <TabPanel value={TransactionType.Manual}>
            <FormControl>
              <FormLabel>Memo</FormLabel>
              <Input value={memo} onChange={(e) => setMemo(e.target.value)} required />
            </FormControl>
            <FormControl>
              <RadioGroup
                aria-labelledby="storage-label"
                size="lg"
                sx={{ gap: 1.5, flexDirection: "row" }}
                onChange={(e) =>
                  setManualType(
                    (e.target.value as ManualTransactionType) ?? ManualTransactionType.Unselected,
                  )
                }
              >
                <Sheet sx={{ p: 2, borderRadius: "md", boxShadow: "sm" }}>
                  <Radio
                    label={ManualTransactionType.Deposit}
                    overlay
                    disableIcon
                    required
                    value={ManualTransactionType.Deposit}
                    slotProps={{
                      label: ({ checked }) => ({
                        sx: {
                          fontWeight: "lg",
                          fontSize: "md",
                          color: checked ? "text.primary" : "text.secondary",
                        },
                      }),
                      action: ({ checked }) => ({
                        sx: (theme) => ({
                          ...(checked && {
                            "--variant-borderWidth": "2px",
                            "&&": {
                              // && to increase the specificity to win the base :hover styles
                              borderColor: theme.vars.palette.primary[500],
                            },
                          }),
                        }),
                      }),
                    }}
                  />
                </Sheet>
                <Sheet sx={{ p: 2, borderRadius: "md", boxShadow: "sm" }}>
                  <Radio
                    label={ManualTransactionType.Withdrawal}
                    overlay
                    disableIcon
                    required
                    value={ManualTransactionType.Withdrawal}
                    slotProps={{
                      label: ({ checked }) => ({
                        sx: {
                          fontWeight: "lg",
                          fontSize: "md",
                          color: checked ? "text.primary" : "text.secondary",
                        },
                      }),
                      action: ({ checked }) => ({
                        sx: (theme) => ({
                          ...(checked && {
                            "--variant-borderWidth": "2px",
                            "&&": {
                              // && to increase the specificity to win the base :hover styles
                              borderColor: theme.vars.palette.primary[500],
                            },
                          }),
                        }),
                      }),
                    }}
                  />
                </Sheet>
              </RadioGroup>
            </FormControl>
            <FormControl>
              <FormLabel>Amount</FormLabel>
              <Input
                startDecorator={<USD />}
                value={amount}
                type="number"
                onChange={(e) => setAmount(e.target.value)}
                required
              />
            </FormControl>
          </TabPanel>
        </Tabs>

        <Checkbox
          label="Prevent auto transfer"
          checked={preventAutoTransfer}
          onChange={() => setPreventAutoTransfer((prevState) => !prevState)}
        />
        <Button type="submit" variant="solid" color="primary">
          {transaction ? "Update Transaction" : "Add Transaction"}
        </Button>
      </Box>
    </Container>
  );
};
