import { CourseDetailsFragment } from "../../../../../../generated/gql/graphql";
import React, { useState } from "react";
import { Form } from "../../../../../../design-system/components/Form";
import { Box, Container, FormControl, FormLabel, Grid, Input, Stack, useTheme } from "@mui/joy";
import { useTextFieldInput } from "../../../../../../hooks/form-hooks";
import { useNavigate } from "react-router-dom";
import { Subject, SubjectPicker } from "../../../../../../components/SubjectPicker";
import { useMutation } from "@apollo/client";
import { createCourseForStudent } from "../mutations/create-course-for-student";
import { getStudentDetailsQuery } from "../../queries/get-student.graphql";
import { updateCourse } from "../mutations/update-course";
import { SaveCancelButtons } from "../../../../../../components/SaveCancelButtons";
import { MarkdownEditor } from "../../../../../../components/MarkdownEditor";
import { useIsOffline } from "../../../../../../hooks/use-online-status";

type Props = {
  studentId: string;
  course?: CourseDetailsFragment;
};

export const AddEditCourseForm: React.FC<Props> = ({ course, studentId }) => {
  const theme = useTheme();
  const isOffline = useIsOffline();
  const [name, setName] = useTextFieldInput(course?.name ?? "");
  const [description, setDescription] = useState(course?.description ?? "");
  const [subjects, setSubjects] = useState<Subject[]>(course?.subjects ?? []);
  const navigate = useNavigate();
  const refetchQueries = [{ query: getStudentDetailsQuery, variables: { id: studentId } }];
  const [addCourse, { loading: addLoading }] = useMutation(createCourseForStudent, {
    variables: {
      input: { name, description, studentId, subjectIds: subjects.map((s) => s.id) },
    },
    refetchQueries,
  });
  const [editCourse, { loading: editLoading }] = useMutation(updateCourse, {
    variables: {
      input: { id: course?.id ?? "", name, description, subjectIds: subjects.map((s) => s.id) },
    },
    refetchQueries,
  });

  const isSubmitting = addLoading || editLoading;

  const onSubmit = async () => {
    if (isSubmitting) {
      return;
    }

    if (subjects.length < 1) {
      alert("At least one subject is required.");
      return;
    }
    if (!name) {
      alert("Name is required.");
      return;
    }

    if (course) {
      const response = await editCourse();
      if (response.data?.updateCourse.success) {
        return navigate(-1);
      }

      const message =
        response.data?.updateCourse.message ?? "An error occurred while saving the course.";
      alert(message);
      console.error(message);
    } else {
      const response = await addCourse();
      if (response.data?.createCourseForStudent.success) {
        return navigate(-1);
      }

      const message =
        response.data?.createCourseForStudent.message ??
        "An error occurred while saving the course.";
      alert(message);
      console.error(message);
    }
  };

  const themeSpacing2 = theme.spacing(2);
  const loading = false;

  return (
    <Box mt={themeSpacing2}>
      <Form onSubmit={onSubmit}>
        <Container maxWidth="md">
          <Stack spacing={2}>
            <FormControl>
              <FormLabel required>Name</FormLabel>
              <Input required id="name" value={name} onChange={setName} />
            </FormControl>
            <SubjectPicker required onChange={setSubjects} value={subjects} />
            <FormControl>
              <FormLabel>Description</FormLabel>
              <MarkdownEditor markdown={description} onChange={setDescription} />
            </FormControl>
          </Stack>
        </Container>
        <SaveCancelButtons
          onCancel={() => navigate(-1)}
          submitting={isSubmitting}
          disabled={isOffline || isSubmitting || loading}
          fixedToBottom
        />
      </Form>
    </Box>
  );
};
