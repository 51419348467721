import { graphql } from "../../../../../../generated/gql";

export const createCourseForStudent = graphql(`
  mutation CreateCourseForStudent($input: CreateCourseForStudentInput!) {
    createCourseForStudent(input: $input) {
      success
      message
      course {
        ...CourseDetails
      }
    }
  }
`);
