import LoginIcon from "@mui/icons-material/Login";

import PersonIcon from "@mui/icons-material/Person";
import { Box, FormControl, FormLabel, Input, useTheme } from "@mui/joy";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

import { JwtUser, useMaybeAuth, User } from "../../auth";
import { LoginSignUpForm } from "../../components/LoginSignUpForm";
import { PasswordField } from "../../design-system/components/PasswordField";
import { SubmitButton } from "../../design-system/components/SubmitButton";
import { useTextFieldInput } from "../../hooks/form-hooks";
import { useLocation } from "../../navigation";
import { Purple } from "../../theme";
import { baseUri } from "../../config/client";

type LoginSuccess = {
  identityToken: string;
  refreshToken: string;
  user: User;
};

type Props = {};

const isSuccess = (result: any): result is LoginSuccess => {
  return (
    (result as LoginSuccess).identityToken !== undefined &&
    (result as LoginSuccess).refreshToken !== undefined &&
    (result as LoginSuccess).user !== undefined
  );
};

export const Login: React.FC<Props> = () => {
  const [email, setEmail] = useTextFieldInput("");
  const [password, setPassword] = useTextFieldInput("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const { setLogin } = useMaybeAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const onSubmit = async () => {
    setLoading(true);
    let response;
    try {
      response = await fetch(`${baseUri}/login`, {
        method: "POST",
        body: JSON.stringify({ email, password }),
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (e) {
      setError("An error occurred. Try again in a little bit.");
      return;
    }

    setLoading(false);
    const data = await response.json();
    if (!response.ok) {
      setError(data.message || "An error occurred. Try again in a little bit.");
      return;
    }

    const jwtUser = jwtDecode<JwtUser>(data.identityToken);
    setLogin({
      identityToken: data.identityToken,
      refreshToken: data.refreshToken,
      user: data.user,
      jwtUser,
    });
    // Send them back to the page they tried to visit when they were
    // redirected to the login page. Use { replace: true } so we don't create
    // another entry in the history stack for the login page.  This means that
    // when they get to the protected page and click the back button, they
    // won't end up back on the login page, which is also really nice for the
    // user experience.
    navigate(from, { replace: true });
  };

  const margin = theme.spacing(4);
  const formSpacing = 2;

  return (
    <Box pt={margin} pb={margin} minHeight="100vh" bgcolor={Purple}>
      <LoginSignUpForm title="Log In" onSubmit={onSubmit} formError={error}>
        <FormControl>
          <FormLabel>Email</FormLabel>
          <Input required startDecorator={<PersonIcon />} value={email} onChange={setEmail} />
        </FormControl>

        <PasswordField required value={password} onChange={setPassword} />
        <Box>
          <SubmitButton
            fullWidth
            size="lg"
            loadingPosition="start"
            startDecorator={<LoginIcon />}
            loading={loading}
          >
            Log in
          </SubmitButton>
        </Box>
      </LoginSignUpForm>
    </Box>
  );
};
