import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Grid,
  Input,
  useTheme,
} from "@mui/joy";

import { Form } from "../../../../../design-system/components/Form";
import { useTextFieldInput } from "../../../../../hooks/form-hooks";
import { edgeFilter } from "../../../../../utils";

import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

import { getStudentListViewQuery } from "../queries/students-list-view.graphql";
import { useMutation } from "@apollo/client";
import { addStudentToAccount } from "../mutations/add-student-to-account";
import {
  StudentDetails,
  studentDetailsFragment,
  StudentDetailsFragmentType,
} from "../queries/get-student.graphql";
import { toFragment } from "../../../../../generated/gql";
import { SaveCancelButtons } from "../../../../../components/SaveCancelButtons";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { updateStudent } from "../mutations/update-student";
import { useIsOffline } from "../../../../../hooks/use-online-status";

type Props = {
  student?: StudentDetails;
  required?: boolean;
};

export const AddEditStudentForm: React.FC<Props> = ({ student, required }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isOffline = useIsOffline();

  const [firstName, setFirstName] = useTextFieldInput(student?.firstName ?? "");
  const [lastName, setLastName] = useTextFieldInput(student?.lastName ?? "");
  const initialBirthdate = student ? DateTime.fromISO(student.birthDate) : null;
  const [birthDate, setBirthDate] = useState<DateTime | null>(initialBirthdate);
  const [addStudent, { loading: addingStudent }] = useMutation(addStudentToAccount, {
    update: (cache, { data: mutationData }, options) => {
      const student = mutationData?.addStudentToAccount.student;
      if (!student) {
        return;
      }

      const data = cache.readQuery({ query: getStudentListViewQuery });
      const students = data?.students;
      if (!students) {
        return;
      }

      // TODO: organization vs individual
      const makeName = (n: { node: StudentDetailsFragmentType }) => {
        const node = toFragment(studentDetailsFragment, n.node);
        return node.firstName;
      };

      const edges = [...students.edges.filter(edgeFilter), { node: student }].sort((a, b) =>
        makeName(a).localeCompare(makeName(b)),
      );

      const result = {
        students: {
          ...students,
          edges,
        },
      };
      cache.writeQuery({ query: getStudentListViewQuery, data: result });
    },
  });

  const [editStudent, { loading: editingStudent }] = useMutation(updateStudent);

  const loading = false;
  const themeSpacing2 = theme.spacing(2);

  const onSubmit = async () => {
    if (!birthDate) {
      alert("Birthdate is required");
      return;
    }
    const commonInput = {
      firstName,
      lastName,
      birthDate: birthDate.toISODate(),
    };
    if (student) {
      const result = await editStudent({
        variables: { input: { id: student.id, ...commonInput } },
      });
      if (result.data?.updateStudent.success && result.data?.updateStudent.student) {
        return navigate(`/students/${student.id}`);
      }
      const message = result.data?.updateStudent.message ?? "An error occurred. Try again.";
      // TODO: real error handling
      alert(message);
    } else {
      const result = await addStudent({
        variables: { input: commonInput },
      });

      if (result.data?.addStudentToAccount.success && result.data?.addStudentToAccount.student) {
        return navigate("/students");
      }
      const message = result.data?.addStudentToAccount.message ?? "An error occurred. Try again.";
      // TODO: real error handling
      alert(message);
      console.error(message);
    }
  };

  const isSubmitting = addingStudent || editingStudent;

  return (
    <Box mt={themeSpacing2}>
      <Form onSubmit={onSubmit}>
        <Container maxWidth="sm">
          <Grid container spacing={themeSpacing2} pl={themeSpacing2} pr={themeSpacing2}>
            <Grid xs={12} sx={{ textAlign: "center" }} mb={theme.spacing(2)}>
              <Badge
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={
                  <Button color="neutral" size="sm" aria-label="Add A Photo">
                    <AddAPhotoIcon sx={{ color: "#FFFFFF" }} />
                  </Button>
                }
              >
                <Avatar sx={{ width: 100, height: 100 }} />
              </Badge>
            </Grid>
            <Grid xs={12} md={6}>
              <FormControl>
                <FormLabel required>First Name</FormLabel>
                <Input required value={firstName} onChange={setFirstName} />
              </FormControl>
            </Grid>
            <Grid xs={12} md={6}>
              <FormControl>
                <FormLabel required>Last Name</FormLabel>
                <Input required value={lastName} onChange={setLastName} />
              </FormControl>
            </Grid>
            <Grid xs={12}>
              <DatePicker
                slotProps={{ textField: { required: true } }}
                openTo="year"
                maxDate={DateTime.now()}
                label="Birthdate"
                value={birthDate}
                onChange={setBirthDate}
                views={["year", "month", "day"]}
              />
            </Grid>
          </Grid>
        </Container>
        <SaveCancelButtons
          onCancel={() => navigate(-1)}
          submitting={isSubmitting}
          disabled={isOffline || isSubmitting || loading}
          fixedToBottom
        />
      </Form>
    </Box>
  );
};
