import { ApolloClient } from "@apollo/client";
import { getSubjectsQuery } from "../../components/SubjectPicker";
import { edgeFilter } from "../../utils";
import {
  getStudentDetailsQuery,
  studentDetailsFragment,
} from "./views/students/queries/get-student.graphql";
import { toFragment } from "../../generated/gql";
import { getCourseDetailsQuery } from "./views/students/courses/queries/course-details";
import { useEffect, useState } from "react";
import { getStudentListViewQuery } from "./views/students/queries/students-list-view.graphql";
import { getAtAGlanceStudentsQuery } from "./views/home/queries/get-students";

export const useInitializeCache = (client: ApolloClient<unknown> | null) => {
  const [initialized, setInitialized] = useState(false);
  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    // only load if the client is initialized and we haven't already initialized the cache
    // and we're not currently initializing and we're not offline.
    if (client && initializing && !initialized) {
      if (!window.navigator.onLine) {
        // If we're offline, this will cause the app to hang, so just skip cache initialization.
        setInitialized(true);
        setInitializing(false);
        return;
      }

      const results = [];
      // In order to make the app experience better in the potential offline scenario
      // we need to grab some initial data.
      results.push(client.query({ query: getAtAGlanceStudentsQuery, fetchPolicy: "network-only" }));
      results.push(client.query({ query: getSubjectsQuery, fetchPolicy: "network-only" }));
      // TODO: this should be disabled for organizations
      results.push(cacheStudents(client));
      Promise.all(results)
        .then(() => {
          setInitialized(true);
          setInitializing(false);
        })
        .catch((e) => {
          console.error("Failed to re-initialize cache: ", e);
          setInitialized(true);
          setInitializing(false);
        });
    }
  }, [client]);

  return initializing && !initialized;
};

const cacheStudents = async (client: ApolloClient<unknown>) => {
  const { data } = await client.query({
    query: getStudentListViewQuery,
    fetchPolicy: "network-only",
  });
  const students = data.students.edges.filter(edgeFilter).map((e) => e.node);
  const result = [];
  for (const student of students) {
    result.push(cacheStudent(client, toFragment(studentDetailsFragment, student).id));
  }

  return result;
};

const cacheStudent = async (client: ApolloClient<unknown>, studentId: string) => {
  const results = [];
  const { data } = await client.query({
    query: getStudentDetailsQuery,
    fetchPolicy: "network-only",
    variables: { id: studentId },
  });
  const student = toFragment(studentDetailsFragment, data.student);

  for (const course of student?.activeCourses ?? []) {
    results.push(cacheCourse(client, course.id));
  }

  return results;
};

const cacheCourse = async (client: ApolloClient<unknown>, courseId: string) => {
  return await client.query({
    query: getCourseDetailsQuery,
    fetchPolicy: "network-only",
    variables: { id: courseId },
  });
};
