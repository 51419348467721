import { ActiveCourseFragment } from "../../../../generated/gql/graphql";
import React, { useState } from "react";
import {
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemContent,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/joy";
import { Dropdown } from "../../../../design-system/components/Dropdown";
import { MenuButton } from "@mui/base";
import { MoreVert } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import { CurrentTask } from "./types";
import RightArrowIcon from "@mui/icons-material/ArrowForward";
import { useIsOffline } from "../../../../hooks/use-online-status";

type Props = {
  course: ActiveCourseFragment;
  studentId: string;
  onMarkCompleted: (course: ActiveCourseFragment, task: CurrentTask) => void;
  isLastItem: boolean;
};
export const CurrentCourseDetails: React.FC<Props> = ({
  course,
  studentId,
  isLastItem,
  onMarkCompleted,
}) => {
  const isOffline = useIsOffline();
  const courseName = course.name;
  const theme = useTheme();
  const currentTask = course.currentTask;
  const courseUrl = `/students/${studentId}/courses/${course.id}`;

  return (
    <React.Fragment>
      <ListItem
        sx={{
          "--ListItem-paddingY": theme.spacing(2),
        }}
        endAction={
          currentTask && (
            <Dropdown>
              <MenuButton slots={{ root: IconButton }}>
                <MoreVert />
              </MenuButton>
              <Menu>
                <MenuItem component={RouterLink} to={courseUrl}>
                  Goto Course <RightArrowIcon />
                </MenuItem>
                {!isOffline && (
                  <MenuItem
                    onClick={() => onMarkCompleted(course, { courseId: course.id, ...currentTask })}
                  >
                    Quick complete
                  </MenuItem>
                )}
              </Menu>
            </Dropdown>
          )
        }
      >
        {currentTask ? (
          <ListItemButton component={RouterLink} to={`${courseUrl}/tasks/${currentTask.id}`}>
            <CurrentTaskSnippet
              courseUrl={courseUrl}
              courseName={courseName}
              currentTask={currentTask}
            />
          </ListItemButton>
        ) : (
          <CurrentTaskSnippet
            courseUrl={courseUrl}
            courseName={courseName}
            currentTask={currentTask}
          />
        )}
      </ListItem>
      {isLastItem && <Divider />}
    </React.Fragment>
  );
};

type CurrentTaskSnippetProps = {
  courseUrl: string;
  courseName: string;
  currentTask?: ActiveCourseFragment["currentTask"] | null;
};
const CurrentTaskSnippet: React.FC<CurrentTaskSnippetProps> = ({
  courseName,
  currentTask,
  courseUrl,
}) => {
  const isOffline = useIsOffline();
  const noTasksMessage = (
    <span>
      No available tasks.{" "}
      {!isOffline && <RouterLink to={`${courseUrl}/tasks/add`}>Need to add one?</RouterLink>}
    </span>
  );

  return (
    <ListItemContent>
      <Typography level="title-sm" fontWeight="bold">
        {courseName}
      </Typography>
      <Typography level="body-sm">{currentTask?.title ?? noTasksMessage}</Typography>
    </ListItemContent>
  );
};
