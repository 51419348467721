import { graphql } from "../../../../../generated/gql";

export const createManualTransactionMutation = graphql(`
  mutation CreateManualTransaction($input: CreateManualTransactionInput!) {
    createManualTransaction(input: $input) {
      success
      message
      transaction {
        id
        amount
        memo
        date
        quantity
      }
    }
  }
`);
