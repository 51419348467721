import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime, Info } from "luxon";

// Luxon uses the browsers I18n support which declares monday as the first day of the week...
// this updates the weekdays array to make Sunday the correct day.
// see this thread: https://github.com/mui/material-ui-pickers/issues/1270
export class MyLuxonAdapter extends AdapterLuxon {
  getWeekdays = () => {
    // need to copy the existing, and use Info to preserve localization
    const days = [...Info.weekdaysFormat("narrow", { locale: this.locale })];
    // remove Sun from end of list and move to start of list
    days.unshift(days.pop() as string);
    return days;
  };

  getWeekArray = (date: DateTime) => {
    const { days } = date
      .endOf("month")
      .endOf("week")
      .diff(date.startOf("month").startOf("week"), "days")
      .toObject();

    let weeks: DateTime[][] = [];
    new Array(Math.round(days as number))
      .fill(0)
      .map((_, i) => i)
      .map((day) => date.startOf("month").startOf("week").minus({ days: 1 }).plus({ days: day }))
      .forEach((v, i) => {
        if (i === 0 || (i % 7 === 0 && i > 6)) {
          weeks.push([v]);
          return;
        }

        weeks[weeks.length - 1].push(v);
      });

    weeks = weeks.filter((week) => {
      // do not allow weeks with start or end outside of current month
      return week[0].hasSame(date, "month") || week[week.length - 1].hasSame(date, "month");
    });

    return weeks;
  };
}
