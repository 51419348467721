import React from "react";
import { Navigate, useLocation } from "../../navigation";
import { useMaybeAuth } from "../hooks";

type Props = {
  children: JSX.Element;
};
export const RequireAuth: React.FC<Props> = ({ children }) => {
  const { user } = useMaybeAuth();
  const location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};
