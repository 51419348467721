import { graphql } from "../../../../../../../generated/gql";

export const deleteCourseTaskMutation = graphql(`
  mutation DeleteCourseTask($input: DeleteCourseTaskInput!) {
    deleteCourseTask(input: $input) {
      success
      message
      course {
        id
        incompleteTasks {
          ...CourseTaskDetails
        }
        completedTasks {
          ...CourseTaskDetails
        }
      }
    }
  }
`);
