import { DateTime, Duration } from "luxon";

export const dateTimeToDateString = (d: DateTime): string => {
  return d.toFormat("yyyy-MM-dd");
};

export function getOperationNameFromQuery(gqlDoc: any): string {
  // It's really a DocumentNode type
  return gqlDoc.definitions.find((d: any) => d.kind === "OperationDefinition").name.value;
}

export const subjectTagListColorPicker = (index: number) =>
  index % 2 === 0 ? "secondary" : "primary";

const TO_HUMAN_UNITS = [
  "years",
  "months",
  "days",
  "hours",
  "minutes",
  "seconds",
  "milliseconds",
] as const;
type TO_HUMAN_UNIT = (typeof TO_HUMAN_UNITS)[number];

export const toHuman = (d: Duration, smallestUnit: TO_HUMAN_UNIT = "seconds"): string => {
  const smallestIdx = TO_HUMAN_UNITS.indexOf(smallestUnit);
  const entries = Object.entries(
    d
      .shiftTo(...TO_HUMAN_UNITS)
      .normalize()
      .toObject(),
  ).filter(([_unit, amount], idx) => amount > 0 && idx <= smallestIdx);
  return Duration.fromObject(
    entries.length === 0 ? { [smallestUnit]: 0 } : Object.fromEntries(entries),
  ).toHuman({
    unitDisplay: "short",
  });
};

export function edgeFilter<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export function toIso8601(hours: string | number, minutes: string | number): string {
  const result = ["PT"];
  if (hours) {
    result.push(`${hours}H`);
  }
  if (minutes) {
    result.push(`${minutes}M`);
  }
  return result.join("");
}
