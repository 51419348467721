import { graphql } from "../../../../../../generated/gql";

export const updateCourse = graphql(`
  mutation UpdateCourse($input: UpdateCourseInput!) {
    updateCourse(input: $input) {
      success
      message
      course {
        ...CourseDetails
      }
    }
  }
`);
