import { Box, FormLabel, Input, Typography } from "@mui/joy";
import React from "react";

type Props = {
  hours: string;
  minutes: string;
  onChangeHours: (hours: string) => void;
  onChangeMinutes: (minutes: string) => void;
};

export const DurationInput: React.FC<Props> = ({
  hours,
  minutes,
  onChangeHours,
  onChangeMinutes,
}) => {
  return (
    <Box>
      <FormLabel id="duration-input-label">Duration</FormLabel>
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Input
          aria-labelledby="duration-input-label"
          type="number"
          value={hours}
          onChange={(e) => onChangeHours(e.target.value)}
          slotProps={{ input: { min: 0 } }}
          endDecorator={<Typography>hours</Typography>}
          sx={{ flex: 1 }}
        />
        <Input
          aria-labelledby="duration-input-label"
          type="number"
          value={minutes}
          onChange={(e) => onChangeMinutes(e.target.value)}
          slotProps={{ input: { min: 0, max: 59 } }}
          endDecorator={<Typography>minutes</Typography>}
          sx={{ flex: 1 }}
        />
      </Box>
    </Box>
  );
};
