import { graphql } from "../../../../../generated/gql";

export const addStudentToAccount = graphql(`
  mutation AddStudentToAccount($input: AddStudentToAccountInput!) {
    addStudentToAccount(input: $input) {
      success
      message
      student {
        ...StudentDetails
      }
    }
  }
`);
