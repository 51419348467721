import { Autocomplete, FormControl, FormLabel, Input } from "@mui/joy";
import React from "react";
import { graphql } from "../generated/gql";
import { useQuery } from "@apollo/client";
import { edgeFilter } from "../utils";
import { GetSubjectsQuery } from "../generated/gql/graphql";

export const getSubjectsQuery = graphql(`
  query GetSubjects {
    subjects {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`);

export type Subject = NonNullable<GetSubjectsQuery["subjects"]["edges"][number]>["node"];

type Props = {
  onChange: (subject: Subject[]) => void;
  value: Subject[] | undefined;
  required?: boolean;
};

export const SubjectPicker: React.FC<Props> = ({ onChange, value, required }) => {
  const { data, loading } = useQuery(getSubjectsQuery);

  const options = data?.subjects.edges.filter(edgeFilter).map((e) => e.node) ?? [];

  return (
    <FormControl>
      <FormLabel required>Subjects</FormLabel>
      <Autocomplete
        multiple
        id="tags-standard"
        options={options}
        loading={loading}
        onChange={(e, value) => onChange(value)}
        getOptionLabel={(option) => option.name}
        value={value}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
    </FormControl>
  );
};
