import React from "react";

import { useLocation } from "react-router-dom";
import { ActivityLogIcon, HomeIcon, StudentsIcon } from "../../icons";
import { NavList } from "./NavList";

import PiggyBankIcon from "@mui/icons-material/Savings";
import ItemsIcon from "@mui/icons-material/Paid";

const SCHOOL_NAV_LIST = [
  {
    to: "/home",
    icon: <HomeIcon />,
    label: "Home",
  },
  {
    to: "/activity-log",
    icon: <ActivityLogIcon />,
    label: "Activity Log",
  },
  {
    to: "/students",
    icon: <StudentsIcon />,
    label: "Students",
  },
];

const MONEY_NAV_LIST = [
  {
    to: "/money/piggy-banks",
    icon: <PiggyBankIcon />,
    label: "Piggy Banks",
  },
  {
    to: "/money/items",
    icon: <ItemsIcon />,
    label: "Items",
  },
];

export const BottomNavigation: React.FC = () => {
  const location = useLocation();

  if (location.pathname.startsWith("/money")) {
    return <NavList items={MONEY_NAV_LIST} />;
  }

  return <NavList items={SCHOOL_NAV_LIST} />;
};
