import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  Sheet,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/joy";
import React from "react";
import { Link as RouterLink, useParams } from "react-router-dom";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useSuspenseQuery } from "@apollo/client";
import { getStudentDetailsQuery, studentDetailsFragment } from "../queries/get-student.graphql";
import { toFragment } from "../../../../../generated/gql";
import { useSetPageTitle } from "../../../../../contexts/app-view";
import RightArrowIcon from "@mui/icons-material/ArrowForward";
import AddIcon from "@mui/icons-material/Add";
import { useIsOffline } from "../../../../../hooks/use-online-status";

type StudentDetailsParams = {
  studentId: string;
};

export const StudentDetails: React.FC = () => {
  useSetPageTitle("Student");
  const isOffline = useIsOffline();
  const params = useParams<StudentDetailsParams>();
  const theme = useTheme();
  const studentId = params.studentId ?? "";
  const { data } = useSuspenseQuery(getStudentDetailsQuery, {
    variables: { id: studentId },
  });

  const student = toFragment(studentDetailsFragment, data.student);

  const deleteStudent = async (options: any) =>
    alert("The API doesn't support deleting students yet.");

  if (!params.studentId || !student) {
    // TODO: something better
    return <div>Student Not Found</div>;
  }

  let addNewCourseButton = (
    <Button
      fullWidth
      variant="outlined"
      disabled={isOffline}
      component={RouterLink}
      to="courses/add"
      color="primary"
      startDecorator={<AddIcon />}
      aria-label="add new course"
    >
      Add new course
    </Button>
  );
  if (isOffline) {
    addNewCourseButton = (
      <Tooltip title="Adding a course is disabled while offline." variant="soft">
        <span>{addNewCourseButton}</span>
      </Tooltip>
    );
  }

  const avatarSx = { width: 100, height: 100, marginLeft: "auto", marginRight: "auto" };

  return (
    <Container maxWidth="md" sx={{ marginTop: theme.spacing(2) }}>
      <Grid container padding={1} spacing={2} alignItems="center">
        <Grid xs={4} md={12}>
          <Avatar sx={avatarSx}>{student.firstName.charAt(0)}</Avatar>
        </Grid>
        <Grid xs={8} md={12}>
          <Box>
            <Typography fontWeight="bold" fontSize="1.5rem">
              {student.firstName} {student.lastName}
            </Typography>
            <Typography level="body-sm">{student.birthDate}</Typography>
          </Box>
        </Grid>
        {!isOffline && (
          <>
            <IconButton component={RouterLink} to={`/students/${student.id}/edit`}>
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={async () => {
                // TODO: this is just a hack to allow for quick deleting for now.
                //       this should handle appropriately updating the cache in the future
                await deleteStudent({ variables: { input: { id: student.id } } });
                // navigate("/students");
              }}
            >
              <DeleteIcon />
            </IconButton>
          </>
        )}
      </Grid>
      <Box sx={{ padding: theme.spacing(1), marginTop: theme.spacing(4) }}>
        <Typography level="h2">Courses</Typography>
        <Sheet>
          <List
            sx={{
              "--ListItem-paddingY": theme.spacing(2),
            }}
          >
            {student.activeCourses.map((course) => {
              const courseUrl = `/students/${student.id}/courses/${course.id}`;
              return (
                <ListItem
                  sx={{ mt: theme.spacing(2) }}
                  key={course.id}
                  endAction={<RightArrowIcon />}
                >
                  <ListItemButton component={RouterLink} to={courseUrl}>
                    <ListItemContent>{course.name}</ListItemContent>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Sheet>
      </Box>
      {addNewCourseButton}
    </Container>
  );
};
