import React from "react";
import { AddEditStudentForm } from "./AddEditStudentForm";
import { useSetPageTitle } from "../../../../../contexts/app-view";

type Props = {};

export const AddStudent: React.FC<Props> = ({}) => {
  useSetPageTitle("Add Student");

  return <AddEditStudentForm />;
};
