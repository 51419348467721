/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query GetStudents {\n    students(first: 10) {\n      edges {\n        node {\n          id\n          firstName\n        }\n      }\n    }\n  }\n": types.GetStudentsDocument,
    "\n  query GetSubjects {\n    subjects {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.GetSubjectsDocument,
    "\n  query GetActivityLog(\n    $sort: [ActivitySort!]\n    $studentIds: [ID!]\n    $first: Int\n    $after: String\n    $last: Int\n    $before: String\n  ) {\n    activities(\n      sort: $sort\n      first: $first\n      after: $after\n      last: $last\n      before: $before\n      studentIds: $studentIds\n    ) {\n      edges {\n        cursor\n        node {\n          id\n          title\n          description\n          dateCreated\n          dateCompleted\n          subjects {\n            id\n            name\n          }\n          students {\n            id\n            firstName\n          }\n          duration\n          notes\n        }\n      }\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n        startCursor\n        endCursor\n      }\n    }\n  }\n": types.GetActivityLogDocument,
    "\n  fragment ActiveCourse on Course {\n    id\n    name\n    description\n    subjects {\n      id\n      name\n    }\n    currentTask {\n      id\n      title\n      description\n      index\n    }\n  }\n": types.ActiveCourseFragmentDoc,
    "\n  query AtAGlanceStudents {\n    students(first: 10) {\n      edges {\n        node {\n          id\n          firstName\n          lastName\n          activeCourses {\n            ...ActiveCourse\n          }\n        }\n      }\n    }\n  }\n": types.AtAGlanceStudentsDocument,
    "\n  mutation AddItem($input: CreatePiggyBankItemInput!) {\n    createPiggyBankItem(input: $input) {\n      success\n      message\n      item {\n        id\n        name\n        amount\n      }\n    }\n  }\n": types.AddItemDocument,
    "\n  mutation AddPiggyBank($input: CreatePiggyBankForStudentInput!) {\n    createPiggyBankForStudent(input: $input) {\n      success\n      message\n      piggyBank {\n        id\n        name\n        balance\n        student {\n          id\n          firstName\n          lastName\n        }\n      }\n    }\n  }\n": types.AddPiggyBankDocument,
    "\n  mutation CreateManualTransaction($input: CreateManualTransactionInput!) {\n    createManualTransaction(input: $input) {\n      success\n      message\n      transaction {\n        id\n        amount\n        memo\n        date\n        quantity\n      }\n    }\n  }\n": types.CreateManualTransactionDocument,
    "\n  mutation CreateTransactionFromItem($input: CreateTransactionFromItemInput!) {\n    createTransactionFromItem(input: $input) {\n      success\n      message\n      transaction {\n        id\n        amount\n        memo\n        date\n        quantity\n      }\n    }\n  }\n": types.CreateTransactionFromItemDocument,
    "\n  mutation UpdateItem($input: UpdatePiggyBankItemInput!) {\n    updatePiggyBankItem(input: $input) {\n      success\n      message\n      item {\n        id\n        name\n        amount\n      }\n    }\n  }\n": types.UpdateItemDocument,
    "\n  query GetItem($id: ID!) {\n    piggyBankItem(id: $id) {\n      id\n      name\n      amount\n    }\n  }\n": types.GetItemDocument,
    "\n  query GetItems($size: Int) {\n    piggyBankItems(first: $size) {\n      edges {\n        node {\n          id\n          name\n          amount\n        }\n      }\n    }\n  }\n": types.GetItemsDocument,
    "\n  query GetPiggyBankDetails($id: ID!, $after: String) {\n    piggyBank(id: $id) {\n      id\n      name\n      balance\n      student {\n        id\n        firstName\n        lastName\n      }\n    }\n    transactions(piggyBankId: $id, after: $after, first: 10) {\n      edges {\n        node {\n          id\n          amount\n          memo\n          date\n          quantity\n          item {\n            id\n            name\n          }\n          piggyBankTransfer {\n            id\n            name\n          }\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n": types.GetPiggyBankDetailsDocument,
    "\n  query GetPiggyBank($id: ID!) {\n    piggyBank(id: $id) {\n      id\n      name\n      balance\n      student {\n        id\n        firstName\n        lastName\n      }\n    }\n  }\n": types.GetPiggyBankDocument,
    "\n  fragment StudentPiggyBanks on Student {\n    id\n    firstName\n    piggyBanks {\n      id\n      balance\n      name\n    }\n  }\n": types.StudentPiggyBanksFragmentDoc,
    "\n  query GetStudentsPiggyBanks {\n    students {\n      edges {\n        node {\n          ...StudentPiggyBanks\n        }\n      }\n    }\n  }\n": types.GetStudentsPiggyBanksDocument,
    "\n  mutation CreateCourseForStudent($input: CreateCourseForStudentInput!) {\n    createCourseForStudent(input: $input) {\n      success\n      message\n      course {\n        ...CourseDetails\n      }\n    }\n  }\n": types.CreateCourseForStudentDocument,
    "\n  mutation UndoCompleteCourseTask($input: UndoCompleteCourseTaskInput!) {\n    undoCompleteCourseTask(input: $input) {\n      success\n      message\n      course {\n        id\n        incompleteTasks {\n          ...CourseTaskDetails\n        }\n        completedTasks {\n          ...CourseTaskDetails\n        }\n      }\n    }\n  }\n": types.UndoCompleteCourseTaskDocument,
    "\n  mutation updateCourseTaskIndex($input: UpdateCourseTaskIndexInput!) {\n    updateCourseTaskIndex(input: $input) {\n      success\n      message\n      course {\n        id\n        incompleteTasks {\n          ...CourseTaskDetails\n        }\n        completedTasks {\n          ...CourseTaskDetails\n        }\n      }\n    }\n  }\n": types.UpdateCourseTaskIndexDocument,
    "\n  mutation UpdateCourse($input: UpdateCourseInput!) {\n    updateCourse(input: $input) {\n      success\n      message\n      course {\n        ...CourseDetails\n      }\n    }\n  }\n": types.UpdateCourseDocument,
    "\n  fragment CourseTaskDetails on CourseTask {\n    id\n    title\n    description\n    isCompleted\n    index\n  }\n": types.CourseTaskDetailsFragmentDoc,
    "\n  fragment CourseDetails on Course {\n    id\n    name\n    description\n    subjects {\n      id\n      name\n    }\n    incompleteTasks {\n      ...CourseTaskDetails\n    }\n    completedTasks {\n      ...CourseTaskDetails\n    }\n  }\n": types.CourseDetailsFragmentDoc,
    "\n  query CourseDetails($id: ID!) {\n    course(id: $id) {\n      ...CourseDetails\n    }\n  }\n": types.CourseDetailsDocument,
    "\n  mutation AddCourseTaskToCourse($input: AddCourseTaskToCourseInput!) {\n    addCourseTaskToCourse(input: $input) {\n      success\n      message\n      course {\n        ...CourseDetails\n      }\n    }\n  }\n": types.AddCourseTaskToCourseDocument,
    "\n  mutation DeleteCourseTask($input: DeleteCourseTaskInput!) {\n    deleteCourseTask(input: $input) {\n      success\n      message\n      course {\n        id\n        incompleteTasks {\n          ...CourseTaskDetails\n        }\n        completedTasks {\n          ...CourseTaskDetails\n        }\n      }\n    }\n  }\n": types.DeleteCourseTaskDocument,
    "\n  mutation MarkTaskComplete($input: CompleteCourseTaskInput!) {\n    completeCourseTask(input: $input) {\n      success\n      message\n      course {\n        ...CourseDetails\n      }\n    }\n  }\n": types.MarkTaskCompleteDocument,
    "\n  mutation UpdateCourseTask($input: UpdateCourseTaskInput!) {\n    updateCourseTask(input: $input) {\n      success\n      message\n      course {\n        ...CourseDetails\n      }\n    }\n  }\n": types.UpdateCourseTaskDocument,
    "\n  mutation AddStudentToAccount($input: AddStudentToAccountInput!) {\n    addStudentToAccount(input: $input) {\n      success\n      message\n      student {\n        ...StudentDetails\n      }\n    }\n  }\n": types.AddStudentToAccountDocument,
    "\n  mutation UpdateStudent($input: UpdateStudentInput!) {\n    updateStudent(input: $input) {\n      success\n      message\n      student {\n        ...StudentDetails\n      }\n    }\n  }\n": types.UpdateStudentDocument,
    "\n  fragment StudentDetails on Student {\n    id\n    firstName\n    lastName\n    birthDate\n    #    persistenceInfo @client {\n    #      isPersisted\n    #      error\n    #    }\n    activeCourses {\n      id\n      name\n      subjects {\n        id\n        name\n      }\n    }\n  }\n": types.StudentDetailsFragmentDoc,
    "\n  query GetStudent($id: ID!) {\n    student(id: $id) {\n      ...StudentDetails\n    }\n  }\n": types.GetStudentDocument,
    "\n  query GetStudentsListView {\n    students {\n      edges {\n        node {\n          ...StudentDetails\n        }\n      }\n    }\n  }\n": types.GetStudentsListViewDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetStudents {\n    students(first: 10) {\n      edges {\n        node {\n          id\n          firstName\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetStudents {\n    students(first: 10) {\n      edges {\n        node {\n          id\n          firstName\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetSubjects {\n    subjects {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetSubjects {\n    subjects {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetActivityLog(\n    $sort: [ActivitySort!]\n    $studentIds: [ID!]\n    $first: Int\n    $after: String\n    $last: Int\n    $before: String\n  ) {\n    activities(\n      sort: $sort\n      first: $first\n      after: $after\n      last: $last\n      before: $before\n      studentIds: $studentIds\n    ) {\n      edges {\n        cursor\n        node {\n          id\n          title\n          description\n          dateCreated\n          dateCompleted\n          subjects {\n            id\n            name\n          }\n          students {\n            id\n            firstName\n          }\n          duration\n          notes\n        }\n      }\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n        startCursor\n        endCursor\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetActivityLog(\n    $sort: [ActivitySort!]\n    $studentIds: [ID!]\n    $first: Int\n    $after: String\n    $last: Int\n    $before: String\n  ) {\n    activities(\n      sort: $sort\n      first: $first\n      after: $after\n      last: $last\n      before: $before\n      studentIds: $studentIds\n    ) {\n      edges {\n        cursor\n        node {\n          id\n          title\n          description\n          dateCreated\n          dateCompleted\n          subjects {\n            id\n            name\n          }\n          students {\n            id\n            firstName\n          }\n          duration\n          notes\n        }\n      }\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n        startCursor\n        endCursor\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ActiveCourse on Course {\n    id\n    name\n    description\n    subjects {\n      id\n      name\n    }\n    currentTask {\n      id\n      title\n      description\n      index\n    }\n  }\n"): (typeof documents)["\n  fragment ActiveCourse on Course {\n    id\n    name\n    description\n    subjects {\n      id\n      name\n    }\n    currentTask {\n      id\n      title\n      description\n      index\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AtAGlanceStudents {\n    students(first: 10) {\n      edges {\n        node {\n          id\n          firstName\n          lastName\n          activeCourses {\n            ...ActiveCourse\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AtAGlanceStudents {\n    students(first: 10) {\n      edges {\n        node {\n          id\n          firstName\n          lastName\n          activeCourses {\n            ...ActiveCourse\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AddItem($input: CreatePiggyBankItemInput!) {\n    createPiggyBankItem(input: $input) {\n      success\n      message\n      item {\n        id\n        name\n        amount\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AddItem($input: CreatePiggyBankItemInput!) {\n    createPiggyBankItem(input: $input) {\n      success\n      message\n      item {\n        id\n        name\n        amount\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AddPiggyBank($input: CreatePiggyBankForStudentInput!) {\n    createPiggyBankForStudent(input: $input) {\n      success\n      message\n      piggyBank {\n        id\n        name\n        balance\n        student {\n          id\n          firstName\n          lastName\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AddPiggyBank($input: CreatePiggyBankForStudentInput!) {\n    createPiggyBankForStudent(input: $input) {\n      success\n      message\n      piggyBank {\n        id\n        name\n        balance\n        student {\n          id\n          firstName\n          lastName\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateManualTransaction($input: CreateManualTransactionInput!) {\n    createManualTransaction(input: $input) {\n      success\n      message\n      transaction {\n        id\n        amount\n        memo\n        date\n        quantity\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateManualTransaction($input: CreateManualTransactionInput!) {\n    createManualTransaction(input: $input) {\n      success\n      message\n      transaction {\n        id\n        amount\n        memo\n        date\n        quantity\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateTransactionFromItem($input: CreateTransactionFromItemInput!) {\n    createTransactionFromItem(input: $input) {\n      success\n      message\n      transaction {\n        id\n        amount\n        memo\n        date\n        quantity\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateTransactionFromItem($input: CreateTransactionFromItemInput!) {\n    createTransactionFromItem(input: $input) {\n      success\n      message\n      transaction {\n        id\n        amount\n        memo\n        date\n        quantity\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateItem($input: UpdatePiggyBankItemInput!) {\n    updatePiggyBankItem(input: $input) {\n      success\n      message\n      item {\n        id\n        name\n        amount\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateItem($input: UpdatePiggyBankItemInput!) {\n    updatePiggyBankItem(input: $input) {\n      success\n      message\n      item {\n        id\n        name\n        amount\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetItem($id: ID!) {\n    piggyBankItem(id: $id) {\n      id\n      name\n      amount\n    }\n  }\n"): (typeof documents)["\n  query GetItem($id: ID!) {\n    piggyBankItem(id: $id) {\n      id\n      name\n      amount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetItems($size: Int) {\n    piggyBankItems(first: $size) {\n      edges {\n        node {\n          id\n          name\n          amount\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetItems($size: Int) {\n    piggyBankItems(first: $size) {\n      edges {\n        node {\n          id\n          name\n          amount\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetPiggyBankDetails($id: ID!, $after: String) {\n    piggyBank(id: $id) {\n      id\n      name\n      balance\n      student {\n        id\n        firstName\n        lastName\n      }\n    }\n    transactions(piggyBankId: $id, after: $after, first: 10) {\n      edges {\n        node {\n          id\n          amount\n          memo\n          date\n          quantity\n          item {\n            id\n            name\n          }\n          piggyBankTransfer {\n            id\n            name\n          }\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetPiggyBankDetails($id: ID!, $after: String) {\n    piggyBank(id: $id) {\n      id\n      name\n      balance\n      student {\n        id\n        firstName\n        lastName\n      }\n    }\n    transactions(piggyBankId: $id, after: $after, first: 10) {\n      edges {\n        node {\n          id\n          amount\n          memo\n          date\n          quantity\n          item {\n            id\n            name\n          }\n          piggyBankTransfer {\n            id\n            name\n          }\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetPiggyBank($id: ID!) {\n    piggyBank(id: $id) {\n      id\n      name\n      balance\n      student {\n        id\n        firstName\n        lastName\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetPiggyBank($id: ID!) {\n    piggyBank(id: $id) {\n      id\n      name\n      balance\n      student {\n        id\n        firstName\n        lastName\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment StudentPiggyBanks on Student {\n    id\n    firstName\n    piggyBanks {\n      id\n      balance\n      name\n    }\n  }\n"): (typeof documents)["\n  fragment StudentPiggyBanks on Student {\n    id\n    firstName\n    piggyBanks {\n      id\n      balance\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetStudentsPiggyBanks {\n    students {\n      edges {\n        node {\n          ...StudentPiggyBanks\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetStudentsPiggyBanks {\n    students {\n      edges {\n        node {\n          ...StudentPiggyBanks\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateCourseForStudent($input: CreateCourseForStudentInput!) {\n    createCourseForStudent(input: $input) {\n      success\n      message\n      course {\n        ...CourseDetails\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateCourseForStudent($input: CreateCourseForStudentInput!) {\n    createCourseForStudent(input: $input) {\n      success\n      message\n      course {\n        ...CourseDetails\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UndoCompleteCourseTask($input: UndoCompleteCourseTaskInput!) {\n    undoCompleteCourseTask(input: $input) {\n      success\n      message\n      course {\n        id\n        incompleteTasks {\n          ...CourseTaskDetails\n        }\n        completedTasks {\n          ...CourseTaskDetails\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UndoCompleteCourseTask($input: UndoCompleteCourseTaskInput!) {\n    undoCompleteCourseTask(input: $input) {\n      success\n      message\n      course {\n        id\n        incompleteTasks {\n          ...CourseTaskDetails\n        }\n        completedTasks {\n          ...CourseTaskDetails\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateCourseTaskIndex($input: UpdateCourseTaskIndexInput!) {\n    updateCourseTaskIndex(input: $input) {\n      success\n      message\n      course {\n        id\n        incompleteTasks {\n          ...CourseTaskDetails\n        }\n        completedTasks {\n          ...CourseTaskDetails\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateCourseTaskIndex($input: UpdateCourseTaskIndexInput!) {\n    updateCourseTaskIndex(input: $input) {\n      success\n      message\n      course {\n        id\n        incompleteTasks {\n          ...CourseTaskDetails\n        }\n        completedTasks {\n          ...CourseTaskDetails\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateCourse($input: UpdateCourseInput!) {\n    updateCourse(input: $input) {\n      success\n      message\n      course {\n        ...CourseDetails\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateCourse($input: UpdateCourseInput!) {\n    updateCourse(input: $input) {\n      success\n      message\n      course {\n        ...CourseDetails\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CourseTaskDetails on CourseTask {\n    id\n    title\n    description\n    isCompleted\n    index\n  }\n"): (typeof documents)["\n  fragment CourseTaskDetails on CourseTask {\n    id\n    title\n    description\n    isCompleted\n    index\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CourseDetails on Course {\n    id\n    name\n    description\n    subjects {\n      id\n      name\n    }\n    incompleteTasks {\n      ...CourseTaskDetails\n    }\n    completedTasks {\n      ...CourseTaskDetails\n    }\n  }\n"): (typeof documents)["\n  fragment CourseDetails on Course {\n    id\n    name\n    description\n    subjects {\n      id\n      name\n    }\n    incompleteTasks {\n      ...CourseTaskDetails\n    }\n    completedTasks {\n      ...CourseTaskDetails\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CourseDetails($id: ID!) {\n    course(id: $id) {\n      ...CourseDetails\n    }\n  }\n"): (typeof documents)["\n  query CourseDetails($id: ID!) {\n    course(id: $id) {\n      ...CourseDetails\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AddCourseTaskToCourse($input: AddCourseTaskToCourseInput!) {\n    addCourseTaskToCourse(input: $input) {\n      success\n      message\n      course {\n        ...CourseDetails\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AddCourseTaskToCourse($input: AddCourseTaskToCourseInput!) {\n    addCourseTaskToCourse(input: $input) {\n      success\n      message\n      course {\n        ...CourseDetails\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteCourseTask($input: DeleteCourseTaskInput!) {\n    deleteCourseTask(input: $input) {\n      success\n      message\n      course {\n        id\n        incompleteTasks {\n          ...CourseTaskDetails\n        }\n        completedTasks {\n          ...CourseTaskDetails\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteCourseTask($input: DeleteCourseTaskInput!) {\n    deleteCourseTask(input: $input) {\n      success\n      message\n      course {\n        id\n        incompleteTasks {\n          ...CourseTaskDetails\n        }\n        completedTasks {\n          ...CourseTaskDetails\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation MarkTaskComplete($input: CompleteCourseTaskInput!) {\n    completeCourseTask(input: $input) {\n      success\n      message\n      course {\n        ...CourseDetails\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation MarkTaskComplete($input: CompleteCourseTaskInput!) {\n    completeCourseTask(input: $input) {\n      success\n      message\n      course {\n        ...CourseDetails\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateCourseTask($input: UpdateCourseTaskInput!) {\n    updateCourseTask(input: $input) {\n      success\n      message\n      course {\n        ...CourseDetails\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateCourseTask($input: UpdateCourseTaskInput!) {\n    updateCourseTask(input: $input) {\n      success\n      message\n      course {\n        ...CourseDetails\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AddStudentToAccount($input: AddStudentToAccountInput!) {\n    addStudentToAccount(input: $input) {\n      success\n      message\n      student {\n        ...StudentDetails\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AddStudentToAccount($input: AddStudentToAccountInput!) {\n    addStudentToAccount(input: $input) {\n      success\n      message\n      student {\n        ...StudentDetails\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateStudent($input: UpdateStudentInput!) {\n    updateStudent(input: $input) {\n      success\n      message\n      student {\n        ...StudentDetails\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateStudent($input: UpdateStudentInput!) {\n    updateStudent(input: $input) {\n      success\n      message\n      student {\n        ...StudentDetails\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment StudentDetails on Student {\n    id\n    firstName\n    lastName\n    birthDate\n    #    persistenceInfo @client {\n    #      isPersisted\n    #      error\n    #    }\n    activeCourses {\n      id\n      name\n      subjects {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment StudentDetails on Student {\n    id\n    firstName\n    lastName\n    birthDate\n    #    persistenceInfo @client {\n    #      isPersisted\n    #      error\n    #    }\n    activeCourses {\n      id\n      name\n      subjects {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetStudent($id: ID!) {\n    student(id: $id) {\n      ...StudentDetails\n    }\n  }\n"): (typeof documents)["\n  query GetStudent($id: ID!) {\n    student(id: $id) {\n      ...StudentDetails\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetStudentsListView {\n    students {\n      edges {\n        node {\n          ...StudentDetails\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetStudentsListView {\n    students {\n      edges {\n        node {\n          ...StudentDetails\n        }\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;