import { graphql } from "../../../../../../generated/gql";

export const undoCompleteCourseTask = graphql(`
  mutation UndoCompleteCourseTask($input: UndoCompleteCourseTaskInput!) {
    undoCompleteCourseTask(input: $input) {
      success
      message
      course {
        id
        incompleteTasks {
          ...CourseTaskDetails
        }
        completedTasks {
          ...CourseTaskDetails
        }
      }
    }
  }
`);
