import React, { useState } from "react";
import { addItemMutation } from "../mutations/add-item";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { getItemsQuery } from "../queries/get-items";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Sheet,
  Typography,
} from "@mui/joy";
import { GetItemQuery } from "../../../../../generated/gql/graphql";
import { updateItemMutation } from "../mutations/update-item";
import { useSetPageTitle } from "../../../../../contexts/app-view";

enum ItemType {
  Income = "Income",
  Expense = "Expense",
  Unselected = "Unselected",
}

type Item = GetItemQuery["piggyBankItem"];

type Props = {
  item?: Item;
};

const getInitialType = (item: Item) => {
  if (!item) {
    return ItemType.Unselected;
  }

  return item.amount > 0 ? ItemType.Income : ItemType.Expense;
};

export const AddEditItem: React.FC<Props> = ({ item }) => {
  useSetPageTitle(item ? "Edit Item" : "Add Item");
  const [name, setName] = useState(item?.name ?? "");
  const [amount, setAmount] = useState<string>(item ? Math.abs(item.amount).toString() : "");
  const [addItem] = useMutation(addItemMutation, { refetchQueries: [getItemsQuery] });
  const [editItem] = useMutation(updateItemMutation, { refetchQueries: [getItemsQuery] });
  const [itemType, setItemType] = useState<ItemType>(getInitialType(item));
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const parsedAmount = parseFloat(amount);

    if (isNaN(parsedAmount)) {
      alert("Amount must be a number");
      return;
    }

    const parsedName = name.trim();
    if (!parsedName) {
      alert("Name is required");
      return;
    }

    if (itemType === ItemType.Unselected) {
      alert("Please select a transaction type");
      return;
    }

    const direction = itemType === ItemType.Income ? 1 : -1;

    if (item) {
      await editItem({
        variables: {
          input: {
            id: item.id,
            name: parsedName,
            amount: parsedAmount * direction,
          },
        },
      });
    } else {
      await addItem({
        variables: {
          input: {
            name: parsedName,
            amount: parsedAmount * direction,
          },
        },
      });
    }

    navigate(-1);
  };

  return (
    <Container>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ display: "flex", flexDirection: "column", gap: 2 }}
      >
        <FormControl>
          <FormLabel>Name</FormLabel>
          <Input value={name} onChange={(e) => setName(e.target.value)} required />
        </FormControl>
        <FormControl>
          <RadioGroup
            aria-labelledby="storage-label"
            size="lg"
            sx={{ gap: 1.5, flexDirection: "row" }}
            onChange={(e) => setItemType((e.target.value as ItemType) ?? ItemType.Unselected)}
          >
            <Sheet sx={{ p: 2, borderRadius: "md", boxShadow: "sm" }}>
              <Radio
                checked={itemType === ItemType.Income}
                label={ItemType.Income}
                overlay
                disableIcon
                required
                value={ItemType.Income}
                slotProps={{
                  label: ({ checked }) => ({
                    sx: {
                      fontWeight: "lg",
                      fontSize: "md",
                      color: checked ? "text.primary" : "text.secondary",
                    },
                  }),
                  action: ({ checked }) => ({
                    sx: (theme) => ({
                      ...(checked && {
                        "--variant-borderWidth": "2px",
                        "&&": {
                          // && to increase the specificity to win the base :hover styles
                          borderColor: theme.vars.palette.primary[500],
                        },
                      }),
                    }),
                  }),
                }}
              />
            </Sheet>
            <Sheet sx={{ p: 2, borderRadius: "md", boxShadow: "sm" }}>
              <Radio
                checked={itemType === ItemType.Expense}
                label={ItemType.Expense}
                overlay
                disableIcon
                required
                value={ItemType.Expense}
                slotProps={{
                  label: ({ checked }) => ({
                    sx: {
                      fontWeight: "lg",
                      fontSize: "md",
                      color: checked ? "text.primary" : "text.secondary",
                    },
                  }),
                  action: ({ checked }) => ({
                    sx: (theme) => ({
                      ...(checked && {
                        "--variant-borderWidth": "2px",
                        "&&": {
                          // && to increase the specificity to win the base :hover styles
                          borderColor: theme.vars.palette.primary[500],
                        },
                      }),
                    }),
                  }),
                }}
              />
            </Sheet>
          </RadioGroup>
        </FormControl>
        <FormControl>
          <FormLabel>Amount</FormLabel>
          <Input
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            type="number"
            required
          />
        </FormControl>
        <Button type="submit" variant="solid" color="primary">
          {item ? "Update Item" : "Add Item"}
        </Button>
      </Box>
    </Container>
  );
};
