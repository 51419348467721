import { useSetPageTitle } from "../../../../../../contexts/app-view";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Chip,
  Container,
  Divider,
  Dropdown,
  Menu,
  MenuButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/joy";
import { useMutation, useQuery } from "@apollo/client";
import {
  courseDetailsFragment,
  getCourseDetailsQuery,
  taskFragment,
} from "../queries/course-details";
import { toFragment } from "../../../../../../generated/gql";
import React from "react";
import { deleteCourseTaskMutation } from "./mutations/delete-course-task";
import { CourseTaskDetailsFragment } from "../../../../../../generated/gql/graphql";
import { undoCompleteCourseTask } from "../mutations/undo-complete-course-task";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { MarkdownEditor } from "../../../../../../components/MarkdownEditor";
import { useIsOffline } from "../../../../../../hooks/use-online-status";

type Props = {};

type Params = {
  courseId: string;
  taskId: string;
};

export const TaskDetails: React.FC<Props> = () => {
  useSetPageTitle("Task Details");
  const isOffline = useIsOffline();
  const params = useParams<Params>();
  const { data } = useQuery(getCourseDetailsQuery, {
    variables: { id: params.courseId ?? "" },
  });
  const [deleteCourseTask] = useMutation(deleteCourseTaskMutation);
  const [undoMarkTaskComplete] = useMutation(undoCompleteCourseTask);
  const navigate = useNavigate();

  const course = toFragment(courseDetailsFragment, data?.course);
  if (!course || !params.taskId) {
    // TODO: something better
    return <div>Course or Task Not Found</div>;
  }
  const findTask = (task: CourseTaskDetailsFragment) => task.id === params.taskId;
  const task =
    toFragment(taskFragment, course.incompleteTasks).find(findTask) ??
    toFragment(taskFragment, course.completedTasks).find(findTask);
  if (!task) {
    return <div>Task not found</div>;
  }

  return (
    <Container maxWidth="md">
      <Stack>
        <Stack direction="row" justifyContent="space-between" py={2}>
          <Box>
            <Typography level="h3">{task.title}</Typography>
            {task.isCompleted && <Chip color="success">Completed</Chip>}
          </Box>
          {!isOffline && (
            <Dropdown>
              <MenuButton size="sm" color="primary">
                <MenuRoundedIcon />
              </MenuButton>
              <Menu>
                {!task.isCompleted ? (
                  <>
                    <MenuItem component={RouterLink} to="edit">
                      Edit
                    </MenuItem>
                    <MenuItem component={RouterLink} to="complete">
                      Complete
                    </MenuItem>
                    <MenuItem
                      onClick={async () => {
                        // TODO: this should be a dialog
                        // eslint-disable-next-line no-restricted-globals
                        const yes = confirm("Are you sure?");
                        if (!yes) {
                          return;
                        }
                        // TODO: this is just a hack to allow for quick deleting for now.
                        //       this should handle appropriately updating the cache in the future
                        await deleteCourseTask({
                          variables: { input: { courseId: course.id, taskId: task.id } },
                        });
                        navigate(-1);
                      }}
                    >
                      Delete
                    </MenuItem>
                  </>
                ) : (
                  <MenuItem
                    onClick={async () => {
                      // TODO: this should be a dialog
                      // eslint-disable-next-line no-restricted-globals
                      const yes = confirm("Are you sure?");
                      if (!yes) {
                        return;
                      }

                      // TODO: better error handling
                      const response = await undoMarkTaskComplete({
                        variables: { input: { courseId: course.id, taskId: task.id } },
                        refetchQueries: [
                          { query: getCourseDetailsQuery, variables: { id: course.id } },
                        ],
                      });
                      if (!response.data?.undoCompleteCourseTask.success) {
                        alert(
                          response.data?.undoCompleteCourseTask.message ?? "Something went wrong",
                        );
                      }
                    }}
                  >
                    Undo Completion
                  </MenuItem>
                )}
              </Menu>
            </Dropdown>
          )}
        </Stack>
        <Divider />
        {/*<Markdown remarkPlugins={[gfm]}></Markdown>*/}
        <MarkdownEditor markdown={task.description || "_No description provided_"} readonly />
      </Stack>
    </Container>
  );
};
