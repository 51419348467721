import { graphql } from "../../../../../generated/gql";

export const getItemQuery = graphql(`
  query GetItem($id: ID!) {
    piggyBankItem(id: $id) {
      id
      name
      amount
    }
  }
`);
