import { graphql } from "../../../../../generated/gql";

export const addPiggyBankMutation = graphql(`
  mutation AddPiggyBank($input: CreatePiggyBankForStudentInput!) {
    createPiggyBankForStudent(input: $input) {
      success
      message
      piggyBank {
        id
        name
        balance
        student {
          id
          firstName
          lastName
        }
      }
    }
  }
`);
