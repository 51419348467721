import React, { createContext, useContext, useEffect, useState } from "react";

export type AppViewContextType = {
  title: React.ReactNode;
  setTitle: (title: React.ReactNode) => void;
};

const AppViewContext = createContext<AppViewContextType>(undefined as any);

export const useAppView = () => useContext(AppViewContext);

type Props = {
  children: React.ReactNode;
};

export const AppViewProvider: React.FC<Props> = ({ children }) => {
  const [title, setTitle] = useState<React.ReactNode | undefined>(undefined);
  const [isNestedView, setIsNestedView] = useState(false);

  return <AppViewContext.Provider value={{ title, setTitle }}>{children}</AppViewContext.Provider>;
};

export const useSetPageTitle = (title?: React.ReactNode, deps: any[] = []) => {
  const { setTitle } = useAppView();
  useEffect(() => {
    setTitle(title);
  }, [title, setTitle]);
};
