import { AddEditTransactionForm } from "./AddEditTransactionForm";
import React from "react";
import { useParams } from "react-router";

export const AddTransaction: React.FC = () => {
  const params = useParams<{ id: string }>();
  if (!params.id) {
    throw new Error("No account ID provided");
  }

  return <AddEditTransactionForm piggyBankId={params.id} />;
};
