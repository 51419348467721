import * as React from "react";
import Box, { BoxProps } from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";
import { Alert, AlertProps, useTheme } from "@mui/joy";
import { useIsNestedView } from "../routes";
import { useIsOffline } from "../hooks/use-online-status";

const FOOTER_HEIGHT = "60px";

function Root(props: BoxProps) {
  return (
    <Box
      {...props}
      sx={[
        {
          bgcolor: "background.appBody",
          display: "grid",
          gridTemplateAreas: {
            xs: `
              "offline"
              "header"
              "main"
            `,
            sm: `
              "offline offline"
              "header  header" 
              "sidenav main" 
              "footer  footer"
            `,
          },
          gridTemplateColumns: {
            xs: "1fr",
            sm: "minmax(64px, 180px) 1fr",
          },
          gridTemplateRows: "min-content 64px 1fr",
          minHeight: "100vh",
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    />
  );
}

function Offline(props: { children: React.ReactNode }) {
  const isOffline = useIsOffline();
  if (isOffline) {
    return (
      <Alert
        color="warning"
        sx={{
          gridArea: "offline",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "text.primary",
          fontWeight: "bold",
          position: "sticky",
          top: 0,
          zIndex: 1100,
        }}
      >
        {props.children}
      </Alert>
    );
  }

  return <Box sx={{ gridArea: "offline" }}></Box>;
}

function Header(props: BoxProps) {
  const isOffline = useIsOffline();

  return (
    <Box
      component="header"
      className="Header"
      {...props}
      sx={[
        {
          p: 2,
          gap: 2,
          gridArea: "header",
          bgcolor: "background.surface",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          gridColumn: "1 / -1",
          borderBottom: "1px solid",
          borderColor: "divider",
          position: "sticky",
          top: isOffline ? 45 : 0,
          zIndex: 1100,
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    />
  );
}

function SideNav(props: BoxProps) {
  return (
    <Box
      component="nav"
      className="Navigation"
      {...props}
      sx={[
        {
          p: 2,
          gridArea: "sidenav",
          bgcolor: "background.surface",
          borderRight: "1px solid",
          borderColor: "divider",
          display: {
            xs: "none",
            sm: "initial",
          },
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    />
  );
}

function SidePane(props: BoxProps) {
  return (
    <Box
      {...props}
      sx={[
        {
          bgcolor: "background.surface",
          borderRight: "1px solid",
          borderColor: "divider",
          display: {
            xs: "none",
            md: "initial",
          },
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    />
  );
}

function Main(props: BoxProps) {
  const theme = useTheme();

  return (
    <Box
      component="main"
      className="Main"
      {...props}
      sx={[
        {
          p: 2,
          gridArea: "main",
          paddingBottom: {
            xs: `calc(${FOOTER_HEIGHT} + ${theme.spacing(2)})`,
            sm: 0,
          },
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    />
  );
}

function SideDrawer(props: BoxProps & { onClose: React.MouseEventHandler<HTMLDivElement> }) {
  const { onClose, ...other } = props;
  return (
    <Box
      {...other}
      sx={[
        { position: "fixed", zIndex: 1200, width: "100%", height: "100%" },
        ...(Array.isArray(other.sx) ? other.sx : [other.sx]),
      ]}
    >
      <Box
        role="button"
        onClick={onClose}
        sx={{
          position: "absolute",
          inset: 0,
          bgcolor: (theme) => `rgba(${theme.vars.palette.neutral.darkChannel} / 0.8)`,
        }}
      />
      <Sheet
        sx={{
          minWidth: 256,
          width: "max-content",
          height: "100%",
          p: 2,
          boxShadow: "lg",
          bgcolor: "background.surface",
        }}
      >
        {other.children}
      </Sheet>
    </Box>
  );
}

function Footer(props: BoxProps) {
  const isNestedView = useIsNestedView();

  return (
    !isNestedView && (
      <Box
        component="footer"
        className="Footer"
        {...props}
        sx={[
          {
            p: 2,
            gap: 2,
            gridArea: "footer",
            width: "100%",
            height: "60px",
            zIndex: 9999,
            "--mui-joy-footerHeight": "60px",
            position: { xs: "fixed", sm: "none" },
            bottom: 0,
            left: 0,
            display: { sm: "none", xs: "flex" },
            bgcolor: "background.surface",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gridColumn: "1 / -1",
            borderTop: "1px solid",
            borderColor: "divider",
          },
          ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
        ]}
      />
    )
  );
}

const Layout = {
  Root,
  Offline,
  Header,
  SideNav,
  SidePane,
  SideDrawer,
  Main,
  Footer,
};

export default Layout;
