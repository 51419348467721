import CancelIcon from "@mui/icons-material/Cancel";
import { Button, ButtonProps } from "@mui/joy";
import React from "react";

type Props = {} & ButtonProps;
export const CancelButton: React.FC<Props> = (props) => {
  return (
    <Button variant="outlined" startDecorator={<CancelIcon />} {...props}>
      {props.children || "Cancel"}
    </Button>
  );
};
