import React from "react";
import { graphql } from "../generated/gql";
import { GetStudentsQuery } from "../generated/gql/graphql";
import { useQuery } from "@apollo/client";
import { edgeFilter } from "../utils";
import { Autocomplete, FormControl, FormLabel } from "@mui/joy";

const studentsQuery = graphql(`
  query GetStudents {
    students(first: 10) {
      edges {
        node {
          id
          firstName
        }
      }
    }
  }
`);

export type Student = NonNullable<GetStudentsQuery["students"]["edges"][number]>["node"];

type Props = {
  onChange: (student: Student[]) => void;
  value: Student[] | undefined;
  required?: boolean;
};

export const StudentPicker: React.FC<Props> = ({ onChange, value, required }) => {
  const { data, loading } = useQuery(studentsQuery);

  const options = data?.students.edges.filter(edgeFilter).map((e) => e.node) ?? [];

  return (
    <FormControl>
      <FormLabel>Students</FormLabel>
      <Autocomplete
        multiple
        id="tags-standard"
        options={options}
        loading={loading}
        onChange={(e, value) => onChange(value)}
        getOptionLabel={(option) => option.firstName}
        value={value}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
    </FormControl>
  );
};
