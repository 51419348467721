import { ApolloClient } from "@apollo/client";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { cache } from "./cache";
import { getLinks } from "./links";
import { QueueLink } from "./queue-link";
import { LocalForageWrapper, persistCache } from "apollo3-cache-persist";
import * as localforage from "localforage";

const stagingUri = "https://peapod-api.local";
const prodUri = "https://api.thepeapod.app";
const localUri = `http://${document.location.hostname}:8080`;
const variant = process.env.REACT_APP_VARIANT as "prod" | "staging" | "local" | "test";
export const baseUri = variant === "prod" ? prodUri : variant === "staging" ? stagingUri : localUri;

if (variant !== "prod") {
  loadDevMessages();
  loadErrorMessages();
}

const graphqlRoute = `${baseUri}/graphql`;

type Props = {
  queueLink: QueueLink;
  refreshToken: () => Promise<string>;
  getIdentityToken: () => string;
};

export const makeClient = async ({ queueLink, refreshToken, getIdentityToken }: Props) => {
  await persistCache({
    cache,
    storage: new LocalForageWrapper(localforage),
  });

  return new ApolloClient({
    uri: graphqlRoute,
    cache,
    link: getLinks({ queueLink, graphqlRoute, baseUri, refreshToken, getIdentityToken }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
    connectToDevTools: process.env.NODE_ENV === "development",
  });
};
