import { toFragment } from "../../../../generated/gql";
import React from "react";
import { Avatar, Button, Card, CardContent, Divider, List, Typography, useTheme } from "@mui/joy";
import { CurrentTask } from "./types";
import { CurrentCourseDetails } from "./CurrentCourseDetails";
import { activeCourseFragment, ActiveCourseFragmentMask } from "./queries/get-students";
import { useIsMobile } from "../../../../hooks/media-queries";
import { ActiveCourseFragment } from "../../../../generated/gql/graphql";
import { RouterLink } from "../../../../design-system/components/RouterLink";
import { useIsOffline } from "../../../../hooks/use-online-status";

type Props = {
  name: string;
  courses: ActiveCourseFragmentMask[];
  studentId: string;
  onMarkCompleted: (course: ActiveCourseFragment, task: CurrentTask) => void;
};

export const StudentGlance: React.FC<Props> = ({ studentId, name, courses, onMarkCompleted }) => {
  const theme = useTheme();
  const isOffline = useIsOffline();
  const activeCourses = toFragment(activeCourseFragment, courses);
  const isMobile = useIsMobile();

  return (
    <Card
      variant="outlined"
      orientation={isMobile ? "vertical" : "horizontal"}
      sx={{
        flex: 1,
        overflow: "auto",
      }}
    >
      <CardContent
        color="primary"
        sx={{
          flex: "1 1 100px",
          display: "flex",
          textAlign: "center",
          flexDirection: isMobile ? "row" : "column",
          justifyContent: isMobile ? "left" : "center",
          alignItems: "center",
          px: "var(--Card-padding)",
        }}
      >
        <Button variant="plain" component={RouterLink} to={`/students/${studentId}`}>
          <Avatar size="lg" sx={{ "--Avatar-size": "60px" }}>
            {name[0]}
          </Avatar>
        </Button>
        <Typography
          level="body-md"
          fontWeight="bold"
          fontSize={{ xs: theme.fontSize.xl2, sm: theme.fontSize.sm }}
        >
          {name}
        </Typography>
      </CardContent>
      <Divider orientation={isMobile ? "horizontal" : "vertical"} />
      <CardContent sx={{ justifyContent: "center" }}>
        {activeCourses.length > 0 ? (
          <List>
            {activeCourses.map((c, idx) => (
              <CurrentCourseDetails
                key={c.id}
                course={c}
                isLastItem={activeCourses.length - 1 !== idx}
                onMarkCompleted={onMarkCompleted}
                studentId={studentId}
              />
            ))}
          </List>
        ) : (
          <Typography textAlign="center" level="body-sm">
            No active courses.{" "}
            {!isOffline && (
              <RouterLink to={`/students/${studentId}/courses/add`}>Need to add one?</RouterLink>
            )}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};
