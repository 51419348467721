import React from "react";
import { AddEditCourseTaskForm } from "./AddEditCourseTaskForm";
import { useParams } from "react-router-dom";
import { useSetPageTitle } from "../../../../../../../contexts/app-view";

type Params = {
  courseId: string;
};
export const AddCourseTask: React.FC = () => {
  useSetPageTitle("Add Course Task");
  const params = useParams<Params>();
  const courseId = params.courseId;
  if (!courseId) {
    // TODO: something better
    return <div>Course Not Found</div>;
  }

  return <AddEditCourseTaskForm courseId={courseId} />;
};
