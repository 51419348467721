import { Box } from "@mui/joy";
import React, { Suspense, useEffect, useState } from "react";
import { AppBar } from "../../design-system/components/AppBar";
import { BottomNavigation } from "../../design-system/components/bottom-navigation/BottomNavigation";
import Layout from "../../components/Layout";
import { NavigationMenu } from "../../components/NavigationMenu";
import { Outlet } from "react-router";
import { RequireAuth, useMaybeAuth } from "../../auth";
import { ApolloClient, ApolloProvider } from "@apollo/client";
import { makeClient } from "../../config/client";
import { AppLoading } from "../../components/AppLoading";
import { ContentLoading } from "../../components/ContentLoading";
import { useInitializeCache } from "./initialize-cache";
import { QueueLink } from "../../config/queue-link";
import { useIsOffline } from "../../hooks/use-online-status";

const queueLink = new QueueLink();

export const MainApp: React.FC = () => {
  const [isNavDrawerOpen, setIsNavDrawerOpen] = useState(false);
  const { isRefreshing, refreshTokenNow, getIdentityToken } = useMaybeAuth();
  const [client, setClient] = useState<ApolloClient<unknown> | null>(null);
  useEffect(() => {
    const getClient = async () =>
      setClient(
        await makeClient({
          queueLink,
          refreshToken: refreshTokenNow,
          getIdentityToken,
        }),
      );

    getClient();
  }, []);
  const initializing = useInitializeCache(client);
  const isOffline = useIsOffline();
  useEffect(() => {
    // queue up the queries and mutations when either
    // offline or when we're attempting to refresh an API token
    if (isOffline || isRefreshing) {
      queueLink.close();
    } else {
      queueLink.open();
    }
  }, [isOffline, isRefreshing]);

  if (!client || initializing) {
    return <AppLoading />;
  }

  const onCloseNavDrawer = () => {
    setIsNavDrawerOpen(false);
  };

  const onOpenNavDrawer = () => {
    setIsNavDrawerOpen(true);
  };

  return (
    <ApolloProvider client={client}>
      <RequireAuth>
        <Box>
          <Layout.Root>
            <Layout.Offline>
              It looks like you're offline. Some actions might not work.
            </Layout.Offline>
            <Layout.Header>
              <AppBar
                isNavDrawerOpen={isNavDrawerOpen}
                onCloseNavDrawer={onCloseNavDrawer}
                onOpenNavDrawer={onOpenNavDrawer}
              />
            </Layout.Header>
            <Layout.SideNav>
              <NavigationMenu />
            </Layout.SideNav>
            <Layout.Main>
              <Suspense fallback={<ContentLoading />}>
                <Outlet />
              </Suspense>
            </Layout.Main>
            <Layout.Footer>
              <BottomNavigation />
            </Layout.Footer>
          </Layout.Root>
        </Box>
      </RequireAuth>
    </ApolloProvider>
  );
};
