import { graphql } from "../../../../../generated/gql";

export const addItemMutation = graphql(`
  mutation AddItem($input: CreatePiggyBankItemInput!) {
    createPiggyBankItem(input: $input) {
      success
      message
      item {
        id
        name
        amount
      }
    }
  }
`);
