import {
  Box,
  DialogTitle,
  Drawer,
  IconButton,
  ModalClose,
  Stack,
  Typography,
  useTheme,
} from "@mui/joy";
import React from "react";
import { useMaybeAuth } from "../../auth";
import { useNavigate } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAppView } from "../../contexts/app-view";

import { useIsNestedView } from "../../routes";
import { NavigationMenu } from "../../components/NavigationMenu";
import LogoImage from "../../images/logo.png";

import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { Image } from "./Image";

const LogoTitle: React.FC = () => {
  const theme = useTheme();

  return (
    <Typography
      color="primary"
      level="h3"
      component="span"
      mb={0}
      fontFamily="Lobster Two"
      fontWeight="normal"
      sx={{
        [`&.MuiTypography-h3`]: { fontSize: "85%" },
      }}
    >
      peapod
    </Typography>
  );
};

type Props = {
  isNavDrawerOpen: boolean;
  onCloseNavDrawer: () => void;
  onOpenNavDrawer: () => void;
};

export const AppBar: React.FC<Props> = ({ isNavDrawerOpen, onCloseNavDrawer, onOpenNavDrawer }) => {
  const { logout } = useMaybeAuth();
  const { title } = useAppView();

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        justifyContent: "space-between",
      }}
    >
      <DesktopTopLeft />
      <MobileTopLeft
        isNavDrawerOpen={isNavDrawerOpen}
        onCloseNavDrawer={onCloseNavDrawer}
        onOpenNavDrawer={onOpenNavDrawer}
      />

      <Box sx={{ flexGrow: 1, textAlign: "center" }}>
        <Typography color="neutral" level="h3" component="span" mb={0}>
          {title ?? <LogoTitle />}
        </Typography>
      </Box>

      <IconButton onClick={logout} aria-label="Log out" sx={{ visibility: "hidden" }}>
        <LogoutIcon />
      </IconButton>
    </Box>
  );
};

const DesktopTopLeft: React.FC = () => {
  const showBackButton = useIsNestedView();
  const navigate = useNavigate();

  return (
    <Box sx={{ display: { xs: "none", sm: "flex" } }}>
      {showBackButton ? (
        <IconButton onClick={() => navigate(-1)} aria-label="Go Back">
          <ArrowBackIcon />
        </IconButton>
      ) : (
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
          <Image height={32} src={LogoImage} alt="Logo" />
        </Stack>
      )}
    </Box>
  );
};

type MobileTopLeftProps = {
  isNavDrawerOpen: boolean;
  onCloseNavDrawer: () => void;
  onOpenNavDrawer: () => void;
};

const MobileTopLeft: React.FC<MobileTopLeftProps> = ({
  isNavDrawerOpen,
  onCloseNavDrawer,
  onOpenNavDrawer,
}) => {
  const showBackButton = useIsNestedView();
  const navigate = useNavigate();

  const onNavDrawerMenuItemClick = () => {
    onCloseNavDrawer();
  };

  return (
    <Box sx={{ display: { xs: "inline-flex", sm: "none" } }}>
      {showBackButton ? (
        <IconButton onClick={() => navigate(-1)} aria-label="Go Back">
          <ArrowBackIcon />
        </IconButton>
      ) : (
        <IconButton variant="plain" color="neutral" onClick={onOpenNavDrawer}>
          <MenuRoundedIcon />
        </IconButton>
      )}

      <Drawer
        sx={{ display: { xs: "inline-flex", sm: "none" } }}
        open={isNavDrawerOpen}
        onClose={onCloseNavDrawer}
      >
        <ModalClose />
        <DialogTitle level="h1">
          <LogoTitle />
        </DialogTitle>
        <Box sx={{ px: 1 }}>
          <NavigationMenu onMenuItemClick={onNavDrawerMenuItemClick} />
        </Box>
      </Drawer>
    </Box>
  );
};
