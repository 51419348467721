import React, { useState } from "react";
import { activityLogQuery } from "./queries/activity-log";
import { useQuery, useSuspenseQuery } from "@apollo/client";
import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Option,
  Select,
  Stack,
  Typography,
} from "@mui/joy";
import { edgeFilter } from "../../../../utils";
import { DateTime } from "luxon";
import { MarkdownEditor } from "../../../../components/MarkdownEditor";
import { CourseSubjects } from "../students/courses/components/CourseSubjects";
import { useSetPageTitle } from "../../../../contexts/app-view";
import {
  ActivitySort,
  ActivitySortDirection,
  ActivitySortField,
} from "../../../../generated/gql/graphql";
import { Student, StudentPicker } from "../../../../components/StudentPicker";
import { useIsMobile } from "../../../../hooks/media-queries";
import { Duration } from "../../../../components/Duration";
import { useIsOffline } from "../../../../hooks/use-online-status";

const PAGE_SIZE = 15;
const DEFAULT_SORT: ActivitySort = {
  field: ActivitySortField.DateCompleted,
  direction: ActivitySortDirection.Desc,
};

enum Sort {
  Newest = "Newest",
  Oldest = "Oldest",
}

const SORT_OPTIONS = [Sort.Newest, Sort.Oldest] as const;

const SORT_MAP = {
  [Sort.Newest]: { field: ActivitySortField.DateCompleted, direction: ActivitySortDirection.Desc },
  [Sort.Oldest]: { field: ActivitySortField.DateCompleted, direction: ActivitySortDirection.Asc },
};

export const ActivityLog: React.FC = () => {
  useSetPageTitle("Activity Log");
  const mobile = useIsMobile();
  const isOffline = useIsOffline();
  const [students, setStudents] = useState<Student[]>([]);
  const [sort, setSort] = useState<ActivitySort>(DEFAULT_SORT);
  const { data, fetchMore } = useSuspenseQuery(activityLogQuery, {
    variables: {
      first: PAGE_SIZE,
      after: null,
      studentIds: students.map((student) => student.id),
      sort: [sort],
    },
  });

  const logItems = data.activities.edges.filter(edgeFilter).map((edge) => edge.node) ?? [];

  let filterComponents = (
    <Grid mb={2} container>
      <Grid md={6} xs={12}>
        <StudentPicker value={students} onChange={setStudents} />
      </Grid>
      <Grid md={6} xs={12}>
        <FormControl>
          <FormLabel>Sort</FormLabel>
          <Select value={sort} onChange={(e, value) => setSort(value ?? DEFAULT_SORT)}>
            {SORT_OPTIONS.map((o) => (
              <Option key={o} value={SORT_MAP[o]}>
                {o}
              </Option>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );

  if (mobile) {
    filterComponents = (
      <AccordionGroup>
        <Accordion>
          <AccordionSummary>Filters</AccordionSummary>
          <AccordionDetails>{filterComponents}</AccordionDetails>
        </Accordion>
      </AccordionGroup>
    );
  }

  return (
    <Box>
      {isOffline && (
        <Alert variant="outlined" color="warning">
          The activities here may be out of date while you are offline.
        </Alert>
      )}
      {filterComponents}
      <Divider />
      <AccordionGroup sx={{ mt: 2 }}>
        {logItems.map((logItem) => (
          <Accordion key={logItem.id}>
            <AccordionSummary>
              <FormattedDate date={logItem.dateCompleted} />
              <Typography flex={1}>{logItem.title}</Typography>
              <Typography>{logItem.students.reduce((acc, s) => acc + s.firstName, "")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Card>
                {/* TODO: either use a different component or make this one generic  */}
                <Typography level="body-sm" fontWeight="bold">
                  Duration:
                </Typography>
                <Duration duration={logItem.duration} />
                <Typography level="body-sm" fontWeight="bold">
                  Subjects:
                </Typography>
                <CourseSubjects subjects={logItem.subjects} />
                <Typography level="body-sm" fontWeight="bold">
                  Evaluation:
                </Typography>
                {logItem.notes && <Typography level="body-md">{logItem.notes}</Typography>}
                <Typography level="body-sm" fontWeight="bold">
                  Description:
                </Typography>
                <MarkdownEditor
                  markdown={logItem.description ?? "_No description provided_"}
                  readonly
                />
              </Card>
            </AccordionDetails>
          </Accordion>
        ))}
      </AccordionGroup>
      <Divider />
      {data && data.activities.pageInfo.hasNextPage && (
        <Button
          sx={{ mt: 2 }}
          fullWidth
          variant="outlined"
          onClick={() =>
            fetchMore({
              variables: {
                after: data.activities.pageInfo.endCursor,
              },
            })
          }
        >
          Load more
        </Button>
      )}
    </Box>
  );
};

const FormattedDate: React.FC<{ date: string }> = ({ date }) => {
  const luxonDate = DateTime.fromISO(date);
  const dateString = luxonDate.toLocaleString({
    month: "numeric",
    day: "numeric",
    year: "numeric",
  });
  const timeString = luxonDate.toLocaleString({ hour: "numeric", minute: "numeric" });

  return (
    <Stack>
      <Typography>{dateString}</Typography>
      <Typography>{timeString}</Typography>
    </Stack>
  );
};
