import React from "react";
import { Box, ListItemDecorator, Tab, TabList, Tabs } from "@mui/joy";
import { useLocation } from "../../../navigation";
import { Link } from "react-router-dom";

type NavItem = {
  icon: React.ReactNode;
  label: string;
  to: string;
};

type Props = {
  items: NavItem[];
};

export const NavList: React.FC<Props> = ({ items }) => {
  const location = useLocation();

  const routes = items.map((i) => i.to);
  const currentTabIndex = routes.indexOf(location.pathname);

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "background.surface",
        boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)",
        zIndex: 1000,
        borderTop: "1px solid #e0e0e0",
      }}
    >
      <Tabs aria-label="Icon tabs" value={currentTabIndex}>
        <TabList tabFlex="auto">
          {items.map((item, index) => (
            <Tab orientation="vertical" component={Link} to={item.to} key={index}>
              <ListItemDecorator>{item.icon}</ListItemDecorator>
              {item.label}
            </Tab>
          ))}
        </TabList>
      </Tabs>
    </Box>
  );
};
