import React from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  Tooltip,
  useTheme,
} from "@mui/joy";
import { Link as RouterLink } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import RightArrowIcon from "@mui/icons-material/ArrowForward";
import { useSuspenseQuery } from "@apollo/client";
import { getStudentListViewQuery } from "./queries/students-list-view.graphql";
import { toFragment } from "../../../../generated/gql";
import { studentDetailsFragment } from "./queries/get-student.graphql";
import { useSetPageTitle } from "../../../../contexts/app-view";
import { edgeFilter } from "../../../../utils";
import { useIsOffline } from "../../../../hooks/use-online-status";

const ADD_STUDENT_URL = `/students/add`;

export const Students = () => {
  useSetPageTitle("Students");
  const isOffline = useIsOffline();
  const theme = useTheme();
  const { data } = useSuspenseQuery(getStudentListViewQuery);

  const students =
    toFragment(
      studentDetailsFragment,
      data?.students.edges.filter(edgeFilter).map((s) => s.node),
    ) ?? [];

  // TODO: if account is organization, show last name
  let addNewStudentButton = (
    <Button
      component={RouterLink}
      to={ADD_STUDENT_URL}
      fullWidth
      color="primary"
      variant="outlined"
      aria-label="add new student"
      startDecorator={<AddIcon />}
      disabled={isOffline}
    >
      Add new student
    </Button>
  );
  if (isOffline) {
    addNewStudentButton = (
      <Tooltip title="Adding a student is disabled while offline." variant="soft">
        <span>{addNewStudentButton}</span>
      </Tooltip>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box sx={{ padding: theme.spacing(1) }}>
        <List
          sx={{
            "--ListItem-paddingY": theme.spacing(2),
          }}
        >
          {students.map((student, idx) => {
            const studentUrl = `/students/${student.id}`;
            return (
              <ListItem key={student.id} sx={{ mt: theme.spacing(2) }}>
                <ListItemButton component={RouterLink} to={studentUrl}>
                  <Avatar size="lg" sx={{ "--Avatar-size": "60px" }}>
                    {student.firstName.charAt(0)}
                  </Avatar>
                  <ListItemContent>{student.firstName}</ListItemContent>
                  <RightArrowIcon />
                </ListItemButton>
              </ListItem>
            );
          })}
          {addNewStudentButton}
        </List>
      </Box>
    </Container>
  );
};
