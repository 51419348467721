import { graphql } from "../../../../../generated/gql";

export const studentPiggyBanksFragment = graphql(`
  fragment StudentPiggyBanks on Student {
    id
    firstName
    piggyBanks {
      id
      balance
      name
    }
  }
`);

export const getStudentPiggyBanksViewQuery = graphql(`
  query GetStudentsPiggyBanks {
    students {
      edges {
        node {
          ...StudentPiggyBanks
        }
      }
    }
  }
`);
