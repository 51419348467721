import { jwtDecode } from "jwt-decode";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocalStorage } from "../hooks/use-local-storage";
import { JwtUser, User } from "./types";
import { baseUri } from "../config/client";
import { DateTime } from "luxon";
import { IDENTITY_TOKEN_KEY, REFRESH_TOKEN_KEY } from "./constants";

type UserAuthDetails = {
  user: User;
  jwtUser: JwtUser;
  identityToken: string;
  refreshToken: string;
};

export type AuthContextType = {
  user: User | null;
  jwtUser: JwtUser | null;
  isRefreshing: boolean;
  getIdentityToken: () => string;
  refreshTokenNow: () => Promise<string>;
  setLogin: (v: UserAuthDetails) => void;
  logout: () => void;
};

export type PathGuardedAuth = {
  user: User;
  jwtUser: JwtUser;
} & AuthContextType;

export const AuthContext = React.createContext<AuthContextType>({
  user: null,
  jwtUser: null,
  isRefreshing: false,
  getIdentityToken: () => "",
  refreshTokenNow: () => Promise.resolve(""),
  setLogin: () => null,
  logout: () => null,
});

export type AuthProviderProps = {
  children: JSX.Element;
};

const setIdentityToken = (token: string) => {
  if (token) {
    localStorage.setItem(IDENTITY_TOKEN_KEY, token);
  }
};
const getIdentityToken = () => {
  return localStorage.getItem(IDENTITY_TOKEN_KEY) ?? "";
};

const setRefreshToken = (token: string) => {
  if (token) {
    localStorage.setItem(REFRESH_TOKEN_KEY, token);
  }
};
const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN_KEY) ?? "";
};

const logout = () => {
  localStorage.removeItem(IDENTITY_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const identityToken = getIdentityToken();
  const refreshToken = getRefreshToken();
  const [user, setUser] = useLocalStorage<User | null>("currentUser", null);
  const jwtUser = identityToken ? jwtDecode<JwtUser>(identityToken) : null;
  const isRefreshingRef = useRef(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const refreshTokenNow = useCallback(async () => {
    if (!isRefreshingRef.current) {
      isRefreshingRef.current = true;
      console.log("refreshTokenNow(): Refreshing token");

      // TODO: refactor baseUri or this function to live somewhere else
      let response;
      try {
        response = await fetch(`${baseUri}/refresh-token`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ refreshToken: getRefreshToken() }),
        });
      } catch (e) {
        console.error("Error refreshing token", e);
        isRefreshingRef.current = false;
        return;
      }

      if (!response.ok) {
        isRefreshingRef.current = false;
        logout();
        window.location.href = "/login";
      }

      const data = await response.json();
      isRefreshingRef.current = false;
      setIdentityToken(data.identityToken);

      return data.identityToken;
    }

    return identityToken;
  }, [refreshToken]);

  useEffect(() => {
    if (identityToken && refreshToken) {
      const jwt = jwtDecode(identityToken);
      // consider the token expired if `.exp` is nullish
      const expirationDate = jwt.exp
        ? DateTime.fromJSDate(new Date(jwt.exp * 1000))
        : // set to some smallish time in the future so the expiration calculation is guaranteed to work
          DateTime.now().plus({ minutes: 5 });
      // the token expires after 24 hours, so let's attempt to refresh at least 12 hours in advance
      const timeBeforeExpirationToRefresh = expirationDate.minus({ hours: 12 });
      // uncomment to force a token refresh in 5 seconds
      // const timeBeforeExpirationToRefresh = expirationDate.minus({
      //   hours: 23,
      //   minute: 59,
      //   seconds: 55,
      // });
      if (DateTime.now() >= timeBeforeExpirationToRefresh) {
        console.log("Token expired, refreshing now:", refreshToken);
        setIsRefreshing(true);
        const done = () => {
          setIsRefreshing(false);
        };
        refreshTokenNow().then(done).catch(done);
      }

      const timeoutDuration = timeBeforeExpirationToRefresh.diffNow();
      console.log("Token expiring in", timeoutDuration.toFormat("hh:mm:ss"));
      const timeoutId = setTimeout(refreshTokenNow, timeoutDuration.as("milliseconds"));
      return () => {
        console.debug("Clearing timeout");
        clearTimeout(timeoutId);
      };
    }
  }, [refreshToken, identityToken, refreshTokenNow]);

  return (
    <AuthContext.Provider
      value={{
        user,
        jwtUser,
        getIdentityToken,
        isRefreshing,
        refreshTokenNow,
        setLogin: ({ user, identityToken, refreshToken }) => {
          setIdentityToken(identityToken);
          setRefreshToken(refreshToken);
          setUser(user);
        },
        logout: () => {
          setIdentityToken("");
          setRefreshToken("");
          setUser(null);
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
