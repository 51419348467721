import { createHttpLink, from } from "@apollo/client";
import { RestLink } from "apollo-link-rest";
import { RetryLink } from "@apollo/client/link/retry";
import { QueueLink } from "./queue-link";
import { ApolloLink, FetchResult, NextLink, Operation } from "@apollo/client/link/core";
import { Observable, ObservableSubscription, Observer } from "@apollo/client/utilities";
import { ZenObservable } from "zen-observable-ts/lib/types";

type Props = {
  baseUri: string;
  graphqlRoute: string;
  queueLink: QueueLink;
  refreshToken: () => Promise<string>;
  getIdentityToken: () => string;
};

type OperationQueueEntry = {
  operation: Operation;
  forward: NextLink;
  observer: Observer<FetchResult>;
};

class AuthRefreshLink extends ApolloLink {
  private queuedRequests: OperationQueueEntry[] = [];
  private isRefreshing = false;

  constructor(private refreshToken: () => Promise<string>) {
    super();
  }

  public request(operation: Operation, forward: NextLink) {
    let sub: ObservableSubscription;
    let retriedSub: ObservableSubscription;
    const checkAndHandle401 = (
      networkError: any,
      observer: ZenObservable.SubscriptionObserver<FetchResult>,
    ) => {
      if (this.isRefreshing) {
        console.debug("Queuing request:", operation.operationName);
        this.queuedRequests.push({ operation, forward, observer });
        return;
      }

      if (networkError && "statusCode" in networkError && networkError.statusCode === 401) {
        console.debug("Got 401, refreshing token");
        this.isRefreshing = true;

        this.refreshToken()
          .then((newToken) => {
            operation.setContext(({ headers = {} }) => ({
              headers: {
                ...headers,
                Authorization: `Bearer ${newToken}`,
              },
            }));
            retriedSub = forward(operation).subscribe(observer);
            this.queuedRequests.forEach((entry) => {
              entry.operation.setContext(({ headers = {} }) => ({
                headers: {
                  ...headers,
                  Authorization: `Bearer ${newToken}`,
                },
              }));
              entry.forward(entry.operation).subscribe(entry.observer);
            });
            this.queuedRequests = [];
            this.isRefreshing = false;
          })
          .catch(() => {
            this.queuedRequests = [];
            this.isRefreshing = false;
            console.error("Failed to refresh token. Forwarding to login page.");
            window.location.href = "/login";
          });
      } else {
        observer.error(networkError);
      }
    };

    return new Observable<FetchResult>((observer) => {
      try {
        sub = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: (networkError) => {
            checkAndHandle401(networkError, observer);
          },
          complete: () => {
            observer.complete();
          },
        });
      } catch (e) {
        checkAndHandle401(e, observer);
      }

      return () => {
        if (sub) sub.unsubscribe();
        if (retriedSub) retriedSub.unsubscribe();
        this.queuedRequests = [];
        this.isRefreshing = false;
      };
    });
  }
}

export const getLinks = ({
  graphqlRoute,
  baseUri,
  queueLink,
  refreshToken,
  getIdentityToken,
}: Props) => {
  return from([
    queueLink,
    new RetryLink({
      delay: {
        initial: 300,
        jitter: true,
      },
      attempts: {
        max: 5,
      },
    }),
    new ApolloLink((operation, forward) => {
      operation.setContext(({ headers: currentHeaders = {} }) => ({
        headers: {
          ...currentHeaders,
          authorization: `Bearer ${getIdentityToken()}`,
        },
      }));
      return forward(operation);
    }),
    new AuthRefreshLink(refreshToken),
    new RestLink({ uri: baseUri }),
    createHttpLink({ uri: graphqlRoute }),
  ]);
};
