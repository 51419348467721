import { graphql } from "../../../../../generated/gql";

export const updateStudent = graphql(`
  mutation UpdateStudent($input: UpdateStudentInput!) {
    updateStudent(input: $input) {
      success
      message
      student {
        ...StudentDetails
      }
    }
  }
`);
