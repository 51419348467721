import { FragmentType, graphql } from "../../../../../../generated/gql";

export const taskFragment = graphql(`
  fragment CourseTaskDetails on CourseTask {
    id
    title
    description
    isCompleted
    index
  }
`);

export type CourseTaskFragmentMask = FragmentType<typeof taskFragment>;

export const courseDetailsFragment = graphql(`
  fragment CourseDetails on Course {
    id
    name
    description
    subjects {
      id
      name
    }
    incompleteTasks {
      ...CourseTaskDetails
    }
    completedTasks {
      ...CourseTaskDetails
    }
  }
`);

export const getCourseDetailsQuery = graphql(`
  query CourseDetails($id: ID!) {
    course(id: $id) {
      ...CourseDetails
    }
  }
`);
