import React from "react";
import { useLocalStorage } from "../hooks/use-local-storage";
import { AuthContext, PathGuardedAuth } from "./context";
import { IDENTITY_TOKEN_KEY, REFRESH_TOKEN_KEY } from "./constants";

export const useMaybeAuth = () => React.useContext(AuthContext);
export const usePathGuardedAuth = (): PathGuardedAuth =>
  React.useContext(AuthContext) as PathGuardedAuth;
export const useLocalStorageIdentityToken = () => useLocalStorage<string>(IDENTITY_TOKEN_KEY, "");
export const useLocalStorageRefreshToken = () => useLocalStorage<string>(REFRESH_TOKEN_KEY, "");
