import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { Box, Button, Container, FormControl, FormLabel, Input, Typography } from "@mui/joy";
import { addPiggyBankMutation } from "../mutations/add-piggy-bank";
import { getPiggyBankDetailsQuery } from "../queries/get-piggy-bank-details";
import { useSetPageTitle } from "../../../../../contexts/app-view";

type props = {
  studentId: string;
  piggyBank?: any;
};

export const AddEditPiggyBank: React.FC<props> = ({ studentId, piggyBank }) => {
  useSetPageTitle(piggyBank ? "Edit Piggy Bank" : "Add Piggy Bank");
  const [name, setName] = useState(piggyBank?.name ?? "");
  const [addPiggyBank] = useMutation(addPiggyBankMutation, {
    refetchQueries: [getPiggyBankDetailsQuery],
  });
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const parsedName = name.trim();
    if (!parsedName) {
      alert("Name is required");
      return;
    }

    if (piggyBank) {
      // await editItem({
      //   variables: {
      //     input: {
      //       id: item.id,
      //       name: parsedName,
      //       amount: parsedAmount * direction,
      //     },
      //   },
      // });
    } else {
      await addPiggyBank({
        variables: {
          input: {
            name: parsedName,
            studentId,
          },
        },
      });
    }

    navigate(-1);
  };

  return (
    <Container>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ display: "flex", flexDirection: "column", gap: 2 }}
      >
        <FormControl>
          <FormLabel>Name</FormLabel>
          <Input value={name} onChange={(e) => setName(e.target.value)} required />
        </FormControl>
        <Button type="submit" variant="solid" color="primary">
          {piggyBank ? "Update Piggy Bank" : "Add Piggy Bank"}
        </Button>
      </Box>
    </Container>
  );
};
