import React from "react";
import "./App.css";
import "./fonts";
import {
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  experimental_extendTheme as materialExtendTheme,
  THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";
import { CssBaseline, CssVarsProvider } from "@mui/joy";
import { browserRouter } from "./routes";
import { RouterProvider } from "react-router-dom";
import { AppViewProvider } from "./contexts/app-view";
import { theme } from "./theme";

// The MUI-X DatePicker component depends on Material UI
// which requires Material UI's theme to be set up.
// We have to provide both for now.
const materialTheme = materialExtendTheme();

function App() {
  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <CssVarsProvider theme={theme}>
        <AppViewProvider>
          <CssBaseline enableColorScheme />
          <RouterProvider router={browserRouter} />
        </AppViewProvider>
      </CssVarsProvider>
    </MaterialCssVarsProvider>
  );
}

export default App;
