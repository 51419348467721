import React, { useState } from "react";
import { toFragment } from "../../../../../../generated/gql";
import { useMutation, useSuspenseQuery } from "@apollo/client";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dropdown,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  Menu,
  MenuItem,
  Sheet,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/joy";
import TaskActionsIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import { useSetPageTitle } from "../../../../../../contexts/app-view";
import {
  courseDetailsFragment,
  getCourseDetailsQuery,
  taskFragment,
} from "../queries/course-details";
import { updateCourseTaskIndex } from "../mutations/update-course-task-index";
import { deleteCourseTaskMutation } from "../tasks/mutations/delete-course-task";
import { CourseSubjects } from "../components/CourseSubjects";
import EditIcon from "@mui/icons-material/Edit";
import { MenuButton } from "@mui/base";
import {
  CourseDetailsFragment,
  CourseTaskDetailsFragment,
} from "../../../../../../generated/gql/graphql";
import { undoCompleteCourseTask } from "../mutations/undo-complete-course-task";
import { MarkdownEditor } from "../../../../../../components/MarkdownEditor";
import { useIsOffline } from "../../../../../../hooks/use-online-status";
import RightArrowIcon from "@mui/icons-material/ArrowForward";

const ADD_TASK_URL = `tasks/add`;

type CourseDetailsParams = {
  courseId: string;
};

export const CourseDetails: React.FC = () => {
  useSetPageTitle("Course Details");
  const params = useParams<CourseDetailsParams>();
  const theme = useTheme();
  const isOffline = useIsOffline();
  const { data } = useSuspenseQuery(getCourseDetailsQuery, {
    variables: { id: params.courseId ?? "" },
  });

  const refetchQueries = [{ query: getCourseDetailsQuery, variables: { id: params.courseId } }];
  const [isDeleting, setIsDeleting] = useState(false);

  const [updateTaskIndex] = useMutation(updateCourseTaskIndex);
  const [deleteTask] = useMutation(deleteCourseTaskMutation);
  const [undoCompletion, { loading: isUndoing }] = useMutation(undoCompleteCourseTask, {
    refetchQueries,
  });

  const course: CourseDetailsFragment = toFragment(courseDetailsFragment, data.course ?? {});
  const completedTasks = toFragment(taskFragment, course.completedTasks);
  const incompleteTasks = toFragment(taskFragment, course.incompleteTasks);

  if (!course) {
    return <div>Course not found</div>;
  }

  const moveTaskUp = async (task: CourseTaskDetailsFragment) => {
    const result = await updateTaskIndex({
      variables: {
        input: {
          courseId: course.id,
          taskId: task.id,
          newIndex: task.index - 1,
        },
      },
      refetchQueries,
    });
    if (!result.data?.updateCourseTaskIndex.success) {
      alert(result.data?.updateCourseTaskIndex.message ?? "Something bad happened.");
      return;
    }
  };

  const moveTaskDown = async (task: CourseTaskDetailsFragment) => {
    const result = await updateTaskIndex({
      variables: {
        input: {
          courseId: course.id,
          taskId: task.id,
          newIndex: task.index + 1,
        },
      },
      refetchQueries,
    });
    if (!result.data?.updateCourseTaskIndex.success) {
      alert(result.data?.updateCourseTaskIndex.message ?? "Something bad happened.");
      return;
    }
  };

  const onDeleteTask = async (task: CourseTaskDetailsFragment) => {
    if (isDeleting) {
      // disallow multiple requests
      return;
    }
    setIsDeleting(true);

    const result = await deleteTask({
      variables: { input: { courseId: course.id, taskId: task.id } },
      refetchQueries: [{ query: getCourseDetailsQuery, variables: { id: course.id } }],
    });
    if (!result.data?.deleteCourseTask.success) {
      // TODO: better error handling
      alert(result.data?.deleteCourseTask.message ?? "Something bad happened");
      return;
    } else {
      setIsDeleting(false);
    }
  };

  const onUndoCompletion = async (task: CourseTaskDetailsFragment) => {
    if (isUndoing) {
      return;
    }

    // eslint-disable-next-line no-restricted-globals
    const yes = confirm("Are you sure?");
    if (!yes) {
      return;
    }

    const response = await undoCompletion({
      variables: { input: { courseId: course.id, taskId: task.id } },
    });
    if (!response.data?.undoCompleteCourseTask.success) {
      alert(response.data?.undoCompleteCourseTask.message ?? "Something bad happened.");
    }
  };

  let addNewTaskButton = (
    <Button
      component={RouterLink}
      to={ADD_TASK_URL}
      fullWidth
      color="primary"
      variant="outlined"
      startDecorator={<AddIcon />}
      aria-label="add new task to course"
      disabled={isOffline}
    >
      Add new task
    </Button>
  );

  if (isOffline) {
    addNewTaskButton = (
      <Tooltip title="Adding a task is disabled while offline." variant="soft">
        <span>{addNewTaskButton}</span>
      </Tooltip>
    );
  }

  return (
    <Container maxWidth="md">
      <Stack direction="row" justifyContent="space-between">
        <Typography level="h3">{course.name}</Typography>
        {!isOffline && (
          <IconButton component={RouterLink} to={`edit`}>
            <EditIcon />
          </IconButton>
        )}
      </Stack>
      <MarkdownEditor markdown={course.description || "_No description provided_"} readonly />
      <CourseSubjects subjects={course.subjects} />

      <Box marginTop={4}>
        <Typography level="h4">Tasks</Typography>
        {incompleteTasks.length > 0 ? (
          <Box sx={{ padding: theme.spacing(1) }}>
            <Sheet>
              <List
                sx={{
                  "--ListItem-paddingY": theme.spacing(2),
                }}
              >
                {incompleteTasks.map((task, idx) => (
                  <TaskItem
                    key={task.id}
                    task={task}
                    isDeleting={isDeleting}
                    isFirstItem={idx === 0}
                    isLastItem={idx === incompleteTasks.length - 1}
                    deleteTask={onDeleteTask}
                    moveTaskUp={moveTaskUp}
                    moveTaskDown={moveTaskDown}
                    undoCompletion={onUndoCompletion}
                  />
                ))}
              </List>
            </Sheet>
          </Box>
        ) : completedTasks.length > 0 ? (
          <Box>
            <Sheet sx={{ padding: theme.spacing(2), textAlign: "center" }}>
              <Typography>All tasks have been completed.</Typography>
            </Sheet>
          </Box>
        ) : (
          <Box>
            <Sheet sx={{ padding: theme.spacing(2), textAlign: "center" }}>
              <Typography>There are no tasks for this course yet.</Typography>
            </Sheet>
          </Box>
        )}
        {addNewTaskButton}

        {completedTasks.length > 0 && (
          <Box mt={4}>
            <Typography level="h4">Completed Tasks</Typography>
            <Box sx={{ padding: theme.spacing(1) }}>
              <Sheet>
                <List
                  sx={{
                    "--ListItem-paddingY": theme.spacing(2),
                  }}
                >
                  {completedTasks.map((task, idx) => (
                    <TaskItem
                      key={task.id}
                      task={task}
                      isDeleting={isDeleting}
                      isFirstItem={idx === 0}
                      isLastItem={idx === completedTasks.length - 1}
                      deleteTask={onDeleteTask}
                      moveTaskUp={moveTaskUp}
                      moveTaskDown={moveTaskDown}
                      undoCompletion={onUndoCompletion}
                    />
                  ))}
                </List>
              </Sheet>
            </Box>
          </Box>
        )}
      </Box>
    </Container>
  );
};

type TaskItemProps = {
  task: CourseTaskDetailsFragment;
  isDeleting: boolean;
  isFirstItem: boolean;
  isLastItem: boolean;
  deleteTask: (task: CourseTaskDetailsFragment) => void;
  moveTaskUp: (task: CourseTaskDetailsFragment) => void;
  moveTaskDown: (task: CourseTaskDetailsFragment) => void;
  undoCompletion: (task: CourseTaskDetailsFragment) => void;
};

const TaskItem: React.FC<TaskItemProps> = ({
  task,
  deleteTask,
  moveTaskUp,
  moveTaskDown,
  isDeleting,
  undoCompletion,
  isFirstItem,
  isLastItem,
}) => {
  const theme = useTheme();
  const taskUrl = `tasks/${task.id}`;
  const isOffline = useIsOffline();

  return (
    <ListItem
      sx={{ mt: theme.spacing(2) }}
      endAction={
        !isOffline ? (
          <Dropdown>
            <MenuButton slots={{ root: IconButton }} slotProps={{ root: { color: "neutral" } }}>
              <TaskActionsIcon />
            </MenuButton>
            <Menu>
              {!task.isCompleted ? (
                <>
                  <MenuItem component={RouterLink} to={taskUrl}>
                    View
                  </MenuItem>
                  <MenuItem component={RouterLink} to={`${taskUrl}/edit`}>
                    Edit
                  </MenuItem>
                  <MenuItem component={RouterLink} to={`${taskUrl}/complete`}>
                    Mark completed
                  </MenuItem>
                  {!isFirstItem && <MenuItem onClick={() => moveTaskUp(task)}>Move Up</MenuItem>}
                  {!isLastItem && <MenuItem onClick={() => moveTaskDown(task)}>Move Down</MenuItem>}
                  <MenuItem onClick={() => deleteTask(task)}>
                    Delete {isDeleting && <CircularProgress />}
                  </MenuItem>
                </>
              ) : (
                <MenuItem onClick={() => undoCompletion(task)}>Undo Completion</MenuItem>
              )}
            </Menu>
          </Dropdown>
        ) : (
          <RightArrowIcon />
        )
      }
    >
      <ListItemButton component={RouterLink} to={taskUrl}>
        <ListItemContent>{task.title}</ListItemContent>
      </ListItemButton>
    </ListItem>
  );
};
