import { InMemoryCache } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        activities: relayStylePagination(),
        student: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: "Student",
              id: args?.id,
            });
          },
        },
      },
    },
    Course: {
      fields: {
        // both of these fields are requested as a part of the Course
        // aggregate, so we should be able to simply return the incoming
        // data as-is in order to get the latest copy.
        incompleteTasks: {
          merge: (existing, incoming) => {
            return incoming;
          },
        },
        completedTasks: {
          merge: (existing, incoming) => {
            return incoming;
          },
        },
      },
    },
  },
});
