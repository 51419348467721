import React from "react";
import LockIcon from "@mui/icons-material/Lock";
import { FormControl, FormLabel, Input, InputProps } from "@mui/joy";

export const PasswordField: React.FC<InputProps> = (props) => {
  return (
    <FormControl>
      <FormLabel>Password</FormLabel>
      <Input type="password" id="password" startDecorator={<LockIcon />} {...props} />
    </FormControl>
  );
};
