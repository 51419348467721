import { graphql } from "../../../../../generated/gql";

export const getPiggyBankDetailsQuery = graphql(`
  query GetPiggyBankDetails($id: ID!, $after: String) {
    piggyBank(id: $id) {
      id
      name
      balance
      student {
        id
        firstName
        lastName
      }
    }
    transactions(piggyBankId: $id, after: $after, first: 10) {
      edges {
        node {
          id
          amount
          memo
          date
          quantity
          item {
            id
            name
          }
          piggyBankTransfer {
            id
            name
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`);
