import { Autocomplete } from "@mui/joy";
import { useQuery } from "@apollo/client";
import { getItemsQuery } from "./queries/get-items";
import { edgeFilter } from "../../../../utils";
import { PiggyBankItem } from "../../../../generated/gql/graphql";

type Props = {
  onSelect: (item: PiggyBankItem | null) => void;
};

export const ItemPicker: React.FC<Props> = ({ onSelect }) => {
  const { data, loading } = useQuery(getItemsQuery);

  const options = data?.piggyBankItems.edges.filter(edgeFilter).map((edge) => edge.node);

  return (
    <Autocomplete
      size="lg"
      placeholder={loading ? "Loading..." : "Select an item"}
      loading={loading}
      disabled={loading}
      options={options ?? []}
      getOptionLabel={(option) => option.name}
      onChange={(_, value) => onSelect(value)}
    />
  );
};
