import React from "react";
import { AddEditPiggyBank } from "./AddEditPiggyBank";
import { useParams } from "react-router-dom";

export const AddPiggyBank: React.FC = () => {
  const params = useParams<{ studentId: string }>();
  if (!params.studentId) {
    throw new Error("No student ID provided");
  }

  return <AddEditPiggyBank studentId={params.studentId} />;
};
