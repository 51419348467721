import {
  List,
  ListDivider,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  ListSubheader,
} from "@mui/joy";
import React from "react";

import { ActivityLogIcon, HomeIcon, PlanningIcon, StudentsIcon } from "../design-system/icons";
import { useLocation } from "react-router-dom";
import { RouterLink } from "../design-system/components/RouterLink";
import { usePathGuardedAuth } from "../auth";
import LogoutIcon from "@mui/icons-material/Logout";

import PiggyBankIcon from "@mui/icons-material/Savings";
import ItemsIcon from "@mui/icons-material/Paid";

type Props = {
  onMenuItemClick?: () => void;
};

export const NavigationMenu: React.FC<Props> = ({ onMenuItemClick }) => {
  const location = useLocation();
  const auth = usePathGuardedAuth();

  return (
    <List size="sm" sx={{ "--ListItem-radius": "var(--joy-radius-sm)", "--List-gap": "4px" }}>
      <ListItem nested>
        <List
          aria-labelledby="nav-list-browse"
          sx={{
            "& .JoyListItemButton-root": { p: "8px" },
          }}
        >
          <ListItem>
            <ListSubheader>Schooling</ListSubheader>
          </ListItem>
          <ListItem>
            <ListItemButton
              component={RouterLink}
              to="/home"
              onClick={onMenuItemClick}
              selected={location.pathname.startsWith("/home")}
            >
              <ListItemDecorator>
                <HomeIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent>Home</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              component={RouterLink}
              to="/activity-log"
              onClick={onMenuItemClick}
              selected={location.pathname.startsWith("/activity-log")}
            >
              <ListItemDecorator sx={{ color: "neutral.500" }}>
                <ActivityLogIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent>Activity Log</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              component={RouterLink}
              to="/students"
              onClick={onMenuItemClick}
              selected={location.pathname.startsWith("/students")}
            >
              <ListItemDecorator sx={{ color: "neutral.500" }}>
                <StudentsIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent>Students</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListDivider />
          <ListItem>
            <ListSubheader>Money</ListSubheader>
          </ListItem>
          <ListItem>
            <ListItemButton
              component={RouterLink}
              to="/money/piggy-banks"
              onClick={onMenuItemClick}
              selected={location.pathname === "/money/piggy-banks"}
            >
              <ListItemDecorator sx={{ color: "neutral.500" }}>
                <PiggyBankIcon fontSize="small" />
              </ListItemDecorator>
              Piggy Banks
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              component={RouterLink}
              to="/money/items"
              onClick={onMenuItemClick}
              selected={location.pathname.startsWith("/money/items")}
            >
              <ListItemDecorator sx={{ color: "neutral.500" }}>
                <ItemsIcon fontSize="small" />
              </ListItemDecorator>
              Items
            </ListItemButton>
          </ListItem>
          <ListDivider />
          <ListItem>
            <ListSubheader>Account</ListSubheader>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={auth.logout}>
              <ListItemDecorator sx={{ color: "neutral.500" }}>
                <LogoutIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent>Log out</ListItemContent>
            </ListItemButton>
          </ListItem>
        </List>
      </ListItem>
    </List>
  );
};
