import { useTheme } from "@mui/joy";
import { useMediaQuery } from "@mui/material";

// const useMediaQuery = (query: string) => {
//   const [matches, setMatches] = useState(false);
//
//   useEffect(() => {
//     const media = window.matchMedia(query);
//     if (media.matches !== matches) {
//       setMatches(media.matches);
//     }
//     const listener = () => setMatches(media.matches);
//     window.addEventListener("resize", listener);
//     return () => window.removeEventListener("resize", listener);
//   }, [matches, query]);
//
//   return matches;
// };

export const useIsMobile = () => {
  const theme = useTheme();

  return useMediaQuery(theme.breakpoints.down("sm"));
};

export const useIsTablet = () => {
  const theme = useTheme();

  return useMediaQuery(theme.breakpoints.between("sm", "md"));
};

export const useIsDesktop = () => {
  const theme = useTheme();

  return useMediaQuery(theme.breakpoints.up("lg"));
};
