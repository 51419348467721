import { graphql } from "../../../../../generated/gql";

export const createTransactionFromItemMutation = graphql(`
  mutation CreateTransactionFromItem($input: CreateTransactionFromItemInput!) {
    createTransactionFromItem(input: $input) {
      success
      message
      transaction {
        id
        amount
        memo
        date
        quantity
      }
    }
  }
`);
