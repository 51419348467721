import { graphql } from "../../../../../generated/gql";

export const getItemsQuery = graphql(`
  query GetItems($size: Int) {
    piggyBankItems(first: $size) {
      edges {
        node {
          id
          name
          amount
        }
      }
    }
  }
`);
