import { Box, CircularProgress } from "@mui/joy";
import React from "react";

export const ContentLoading: React.FC = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress size="lg" />
    </Box>
  );
};
