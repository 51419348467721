import { useSuspenseQuery } from "@apollo/client";
import { getItemsQuery } from "../queries/get-items";
import { edgeFilter } from "../../../../../utils";
import { Box, Container, IconButton, List, ListItem, ListItemContent, Typography } from "@mui/joy";
import { Money } from "../../../../../components/Money";
import { useSetPageTitle } from "../../../../../contexts/app-view";
import { Fab } from "../../../../../components/Fab";
import { RouterLink } from "../../../../../design-system/components/RouterLink";

import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";

export const ItemsList: React.FC = () => {
  useSetPageTitle("Items");
  const { data } = useSuspenseQuery(getItemsQuery);
  const items = data?.piggyBankItems.edges.filter(edgeFilter).map((edge) => edge.node) ?? [];

  return (
    <Container>
      {items.length ? (
        <List>
          {items.map((item) => (
            <ListItem
              key={item.id}
              sx={{ display: "flex", justifyContent: "center" }}
              endAction={
                <Box>
                  <IconButton
                    aria-label="Edit"
                    size="sm"
                    color="primary"
                    component={RouterLink}
                    to={`${item.id}/edit`}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton aria-label="Delete" size="sm" color="danger">
                    <Delete />
                  </IconButton>
                </Box>
              }
            >
              <ListItemContent>
                <Typography level="title-lg">{item.name}</Typography>
                <Typography level="title-sm">
                  <Money value={item.amount} />
                </Typography>
              </ListItemContent>
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography textAlign="center">No items found</Typography>
      )}
      <Fab to="add" />
    </Container>
  );
};
