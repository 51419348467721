import { graphql } from "../../../../../generated/gql";

export const updateItemMutation = graphql(`
  mutation UpdateItem($input: UpdatePiggyBankItemInput!) {
    updatePiggyBankItem(input: $input) {
      success
      message
      item {
        id
        name
        amount
      }
    }
  }
`);
