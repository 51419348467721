import React, { useTransition } from "react";
import { useParams } from "react-router-dom";
import { NetworkStatus, useSuspenseQuery } from "@apollo/client";
import { getPiggyBankDetailsQuery } from "../queries/get-piggy-bank-details";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  List,
  ListItem,
  ListItemContent,
  Stack,
  Typography,
} from "@mui/joy";
import { Money } from "../../../../../components/Money";
import { Fab } from "../../../../../components/Fab";
import { edgeFilter } from "../../../../../utils";

type Props = {};

export const PiggyBankDetails: React.FC<Props> = () => {
  const params = useParams<{ id: string }>();
  const { id } = params;

  if (!id) {
    throw new Error("No piggy bank ID provided");
  }

  const [isFetchMorePending, startTransition] = useTransition();
  const { data, fetchMore } = useSuspenseQuery(getPiggyBankDetailsQuery, {
    variables: { id },
    fetchPolicy: "cache-and-network",
  });
  const piggyBank = data.piggyBank;
  if (!piggyBank) {
    return <p>Can't find a piggy bank with that ID</p>;
  }
  const hasNextPage = data.transactions.pageInfo.hasNextPage;
  const endCursor = data.transactions.pageInfo.endCursor;
  const transactions = data.transactions.edges.filter(edgeFilter).map((edge) => edge.node);
  const student = piggyBank.student;

  const loadMore = async () => {
    // prevent suspense from showing loading spinner for a refetch
    startTransition(() => {
      fetchMore({
        variables: { id, after: endCursor },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return previousResult;

          return {
            ...previousResult,
            transactions: {
              ...previousResult.transactions,
              edges: [...previousResult.transactions.edges, ...fetchMoreResult.transactions.edges],
              pageInfo: fetchMoreResult.transactions.pageInfo,
            },
          };
        },
      });
    });
  };

  return (
    <Container>
      <Stack direction="row" alignItems="end">
        <Typography component="p" level="h1" sx={{ p: 0 }}>
          {student.firstName} - {piggyBank.name}
        </Typography>
        <Typography component="p" level="h3" sx={{ flex: 1, textAlign: "right" }}>
          Balance: <Money value={piggyBank.balance} />
        </Typography>
      </Stack>
      <List>
        {(transactions ?? []).map((transaction) => (
          <ListItem key={transaction.id}>
            <ListItemContent>
              <Card>
                <Stack direction="row">
                  <StackedDate date={transaction.date} />
                  <Box flex={1} textAlign="right">
                    <Typography flex={1} level="title-lg">
                      {transaction.memo}
                    </Typography>
                    {transaction.quantity > 1 && (
                      <Typography>
                        {transaction.quantity} x <Money value={transaction.amount} />
                      </Typography>
                    )}
                    <Typography>
                      <Money value={transaction.amount * transaction.quantity} />
                    </Typography>
                  </Box>
                </Stack>
              </Card>
            </ListItemContent>
          </ListItem>
        ))}
      </List>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        {isFetchMorePending && <CircularProgress />}
        <Button variant="outlined" onClick={loadMore} disabled={!hasNextPage}>
          Load More
        </Button>
      </Box>
      <Box sx={{ height: 150 }} />
      <Fab to={`transactions/add`} />
    </Container>
  );
};

type StackedDateProps = {
  date: string;
};

const StackedDate: React.FC<StackedDateProps> = ({ date }) => {
  const jsDate = new Date(date);

  return (
    <Stack direction="column" alignItems="center">
      <Typography>{jsDate.toLocaleDateString()}</Typography>
      <Typography>{jsDate.toLocaleTimeString()}</Typography>
    </Stack>
  );
};
