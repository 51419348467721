import { graphql } from "../../../../../generated/gql";

export const getStudentListViewQuery = graphql(`
  query GetStudentsListView {
    students {
      edges {
        node {
          ...StudentDetails
        }
      }
    }
  }
`);
