import React from "react";
import {
  Location as ReactRouterLocation,
  Navigate as ReactRouterNavigate,
  useLocation as reactRouterUseLocation,
} from "react-router-dom";

type Location = {
  state?: State;
} & ReactRouterLocation;

type State = {
  from?: Location;
  title?: string;
};

type Props = {
  to: string;
  state?: State;
  replace?: boolean;
};

export const Navigate: React.FC<Props> = (props) => {
  return <ReactRouterNavigate {...props} />;
};

export const useLocation = (): Location => {
  return reactRouterUseLocation() as Location;
};
