import { DocumentType, FragmentType, graphql } from "../../../../../generated/gql";
export type { StudentDetailsFragment } from "../../../../../generated/gql/graphql";

export const studentDetailsFragment = graphql(`
  fragment StudentDetails on Student {
    id
    firstName
    lastName
    birthDate
    #    persistenceInfo @client {
    #      isPersisted
    #      error
    #    }
    activeCourses {
      id
      name
      subjects {
        id
        name
      }
    }
  }
`);

export type StudentDetailsFragmentType = FragmentType<typeof studentDetailsFragment>;
export type StudentDetails = DocumentType<typeof studentDetailsFragment>;

export const getStudentDetailsQuery = graphql(`
  query GetStudent($id: ID!) {
    student(id: $id) {
      ...StudentDetails
    }
  }
`);
