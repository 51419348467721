import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { courseDetailsFragment, getCourseDetailsQuery } from "../../queries/course-details";
import { findTaskById } from "../utils";
import { toFragment } from "../../../../../../../generated/gql";
import { CompleteTaskForm } from "./CompleteTaskForm";

type Params = {
  courseId: string;
  taskId: string;
};

export const CompleteTask: React.FC = () => {
  const params = useParams<Params>();
  const { data, loading } = useQuery(getCourseDetailsQuery, {
    variables: { id: params?.courseId ?? "" },
    skip: !params?.courseId,
  });

  // TODO: this needs to be better
  const notFoundRedirect = <Navigate to={"/"} replace />;

  if (!params.courseId || !params.taskId) {
    return notFoundRedirect;
  }

  if (loading) {
    // TODO: better loading
    return <div>Loading...</div>;
  }
  if (!data?.course) {
    return notFoundRedirect;
  }

  const course = toFragment(courseDetailsFragment, data?.course);
  const task = findTaskById(course, params.taskId);

  if (!task) {
    return notFoundRedirect;
  }

  return <CompleteTaskForm course={course} task={task} />;
};
