import React from "react";
import { Box, CircularProgress } from "@mui/joy";

export const AppLoading: React.FC = () => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress size="lg" />
    </Box>
  );
};
