import React, { useState } from "react";
import { toIso8601 } from "../../../../../../../utils";
import {
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Textarea,
  Typography,
} from "@mui/joy";
import { CourseSubjects } from "../../components/CourseSubjects";
import { MarkdownEditor } from "../../../../../../../components/MarkdownEditor";
import { SubjectPicker } from "../../../../../../../components/SubjectPicker";
import { DurationInput } from "../../../../../../../design-system/components/DurationInput";
import { SaveCancelButtons } from "../../../../../../../components/SaveCancelButtons";
import { useTextFieldInput } from "../../../../../../../hooks/form-hooks";
import { useMutation } from "@apollo/client";
import { markTaskComplete } from "../mutations/mark-task-complete";
import { getAtAGlanceStudentsQuery } from "../../../../home/queries/get-students";
import {
  CourseDetailsFragment,
  CourseTaskDetailsFragment,
} from "../../../../../../../generated/gql/graphql";
import { useNavigate } from "react-router-dom";
import { DateTimePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";

type Props = {
  course: CourseDetailsFragment;
  task: CourseTaskDetailsFragment;
};

export const CompleteTaskForm: React.FC<Props> = ({ course, task }) => {
  const [title, setTitle] = useTextFieldInput(task.title);
  const [description, setDescription] = useState(task.description ?? "");
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [subjects, setSubjects] = useState(course.subjects);
  const [notes, setNotes] = useTextFieldInput("");
  const [useTaskDetails, setUseTaskDetails] = useState(true);
  const [isDefaultDateTime, setIsDefaultDateTime] = useState(true);
  const [dateCompleted, setDateCompleted] = useState<DateTime | null>(
    DateTime.fromJSDate(new Date()),
  );
  const [completeCourseTaskMutation, { loading: isSubmitting }] = useMutation(markTaskComplete, {
    refetchQueries: [getAtAGlanceStudentsQuery],
  });
  const navigate = useNavigate();
  const handleCompletedDateChange = (date: DateTime | null) => {
    setDateCompleted(date);
    setIsDefaultDateTime(false);
  };

  return (
    <Container maxWidth="md">
      <form
        onSubmit={async (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();

          if (!hours.trim() && !minutes.trim()) {
            alert("Duration is required.");
            return;
          }

          if (!useTaskDetails) {
            if (!title.trim()) {
              alert("Title is required.");
              return;
            }
            if (!subjects.length) {
              alert("At least one subject is required.");
              return;
            }
          }

          const overrides = useTaskDetails
            ? {}
            : {
                title: title.trim(),
                description: description.trim(),
                subjectIds: subjects.map((s) => s.id),
              };

          const duration = toIso8601(hours, minutes);

          const response = await completeCourseTaskMutation({
            variables: {
              input: {
                courseId: course.id,
                taskId: task.id,
                duration,
                notes: notes.trim() || null,
                dateCompleted: isDefaultDateTime ? null : dateCompleted?.toISO() ?? undefined,
                ...overrides,
              },
            },
          });

          // TODO: handle errors
          if (response.data?.completeCourseTask.success) {
            navigate(-1);
          } else {
            alert(response.data?.completeCourseTask.message ?? "Something bad happened.");
          }
        }}
      >
        <Stack spacing={2}>
          <FormControl>
            <FormLabel>Use details from task?</FormLabel>
            <Checkbox
              checked={useTaskDetails}
              onChange={(e) => setUseTaskDetails(e.target.checked)}
            />
          </FormControl>
          <Divider />
          {useTaskDetails ? (
            <>
              <FormControl>
                <FormLabel sx={{ fontWeight: "bold" }}>Title</FormLabel>
                <Typography level="body-sm">{task.title}</Typography>
              </FormControl>
              <FormControl>
                <FormLabel sx={{ fontWeight: "bold" }}>Subjects</FormLabel>
                <CourseSubjects subjects={course.subjects} />
              </FormControl>
              <FormControl>
                <FormLabel sx={{ fontWeight: "bold" }}>Description</FormLabel>
                <MarkdownEditor
                  sx={{ maxHeight: "200px", overflowY: "auto" }}
                  markdown={task.description || "_No description provided_"}
                  readonly
                />
              </FormControl>
            </>
          ) : (
            <>
              <FormControl>
                <FormLabel>Title</FormLabel>
                <Input required onChange={setTitle} value={title} />
              </FormControl>
              <FormControl>
                <SubjectPicker onChange={setSubjects} value={subjects} />
              </FormControl>
              <FormControl>
                <FormLabel>Description</FormLabel>
                <MarkdownEditor
                  sx={{ maxHeight: "200px", overflowY: "auto" }}
                  markdown={description}
                  onChange={setDescription}
                />
              </FormControl>
            </>
          )}
          <FormControl>
            <FormLabel>Date Completed</FormLabel>
            <DateTimePicker
              disableFuture
              value={dateCompleted}
              onChange={handleCompletedDateChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Evaluation</FormLabel>
            <Textarea minRows={2} maxRows={4} onChange={setNotes} value={notes} />
          </FormControl>
          <DurationInput
            hours={hours}
            onChangeHours={setHours}
            minutes={minutes}
            onChangeMinutes={setMinutes}
          />
          <SaveCancelButtons fixedToBottom onCancel={() => navigate(-1)} disabled={isSubmitting} />
        </Stack>
      </form>
    </Container>
  );
};
