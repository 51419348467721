import { graphql } from "../../../../../../../generated/gql";

export const addCourseTaskToCourse = graphql(`
  mutation AddCourseTaskToCourse($input: AddCourseTaskToCourseInput!) {
    addCourseTaskToCourse(input: $input) {
      success
      message
      course {
        ...CourseDetails
      }
    }
  }
`);
