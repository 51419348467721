import { useParams } from "react-router-dom";
import React from "react";
import {
  courseDetailsFragment,
  getCourseDetailsQuery,
  taskFragment,
} from "../../queries/course-details";
import { useQuery } from "@apollo/client";
import { toFragment } from "../../../../../../../generated/gql";
import { AddEditCourseTaskForm } from "./AddEditCourseTaskForm";
import { useSetPageTitle } from "../../../../../../../contexts/app-view";
import { CourseTaskDetailsFragment } from "../../../../../../../generated/gql/graphql";

type Params = {
  courseId: string;
  taskId: string;
};

export const EditCourseTask: React.FC = () => {
  useSetPageTitle("Edit Course Task");
  const params = useParams<Params>();
  const courseId = params.courseId;
  const { data, loading } = useQuery(getCourseDetailsQuery, {
    variables: { id: courseId ?? "" },
    skip: !courseId,
  });

  if (!courseId || !params.taskId) {
    // TODO: something better
    return <div>Course Not Found</div>;
  }

  if (loading) {
    // TODO: something better
    return <div>Loading</div>;
  }

  const course = toFragment(courseDetailsFragment, data?.course);
  // TODO: this is only searching the incomplete tasks since today,
  //  we only allow editing of incomplete tasks
  const findTask = (task: CourseTaskDetailsFragment) => task.id === params.taskId;
  const task =
    toFragment(taskFragment, course?.incompleteTasks ?? []).find(findTask) ??
    toFragment(taskFragment, course?.completedTasks ?? []).find(findTask);

  return <AddEditCourseTaskForm courseId={courseId} task={task} />;
};
