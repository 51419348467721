import { LinkBehavior } from "./design-system/components/LinkBehavior";
import { extendTheme } from "@mui/joy";

// export const theme = responsiveFontSizes(
//   createTheme({
//     palette: {
//       background: {
//         default: "#FFF",
//       },
//       primary: {
//         main: "#96BC33",
//         // light: "#A9CF4B",
//         // dark: "#88A53D",
//       },
//       secondary: {
//         main: "#16BECF",
//       },
//       tertiary: {
//         main: "#9575cd",
//       },
//       action: {
//         selectedOpacity: 0.8,
//       },
//     },
//     typography: {
//       fontSize: 16,
//       fontFamily: "OpenSans Condensed, Roboto",
//     },
//     components: {
//       MuiLink: {
//         defaultProps: {
//           component: LinkBehavior,
//         } as any,
//       },
//       MuiButtonBase: {
//         defaultProps: {
//           LinkComponent: LinkBehavior,
//         },
//       },
//       MuiAppBar: {
//         styleOverrides: {
//           colorPrimary: "#FFFFFF",
//         },
//       },
//     },
//     constants: {
//       bottomNavbarHeight: 7, // spacing elements
//     },
//   }),
// );

const headerUpdates = {
  fontFamily: "Josefin Slab, Roboto",
};
const variants = ["h1", "h2", "h3", "h4", "h5", "h6"] as const;
// for (const h of variants) {
//   Object.assign(theme.typography[h], headerUpdates);
// }

export const theme = extendTheme();

export const Purple = "#9575cd";
