import React from "react";

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

type Props = {
  value: number | string;
};

export const Money: React.FC<Props> = ({ value }) => {
  if (typeof value === "string") {
    value = parseFloat(value);
  }

  return <>{currencyFormatter.format(value)}</>;
};
