import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PlusIcon from "@mui/icons-material/Add";
import { Button } from "@mui/joy";

type Props = {
  to: string;
};

export const Fab: React.FC<Props> = ({ to }) => {
  return (
    <Button
      variant="solid"
      color="primary"
      sx={{
        position: "fixed",
        bottom: 96,
        right: 32,
        borderRadius: "50%",
        width: 56,
        height: 56,
        minWidth: 0,
      }}
      component={RouterLink}
      to={to}
    >
      <PlusIcon />
    </Button>
  );
};
