import { graphql } from "../../../../../../generated/gql";

export const updateCourseTaskIndex = graphql(`
  mutation updateCourseTaskIndex($input: UpdateCourseTaskIndexInput!) {
    updateCourseTaskIndex(input: $input) {
      success
      message
      course {
        id
        incompleteTasks {
          ...CourseTaskDetails
        }
        completedTasks {
          ...CourseTaskDetails
        }
      }
    }
  }
`);
