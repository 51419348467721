import React, { FormEventHandler } from "react";

type Props = {
  onSubmit: () => void;
  children: JSX.Element | JSX.Element[];
} & React.FormHTMLAttributes<HTMLFormElement>;

export const Form: React.FC<Props> = ({ onSubmit, children }) => {
  const onFormSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    onSubmit();
  };

  return <form onSubmit={onFormSubmit}>{children}</form>;
};
