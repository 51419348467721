import { AddEditCourseForm } from "./AddEditCourseForm";
import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { courseDetailsFragment, getCourseDetailsQuery } from "../queries/course-details";
import { toFragment } from "../../../../../../generated/gql";
import { useSetPageTitle } from "../../../../../../contexts/app-view";

type Params = {
  courseId: string;
  studentId: string;
};

export const EditCourse: React.FC = () => {
  useSetPageTitle("Edit Course");
  const params = useParams<Params>();
  const { data, loading } = useQuery(getCourseDetailsQuery, {
    variables: { id: params.courseId ?? "" },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!data?.course || !params.studentId) {
    return <div>Not found...</div>;
  }

  const course = toFragment(courseDetailsFragment, data.course);

  return <AddEditCourseForm studentId={params.studentId} course={course} />;
};
