import React, { useState } from "react";
import { useTextFieldInput } from "../../../../../../../hooks/form-hooks";
import { CourseTaskDetailsFragment } from "../../../../../../../generated/gql/graphql";
import { Box, Container, FormControl, FormLabel, Input, Stack, useTheme } from "@mui/joy";
import { Form } from "../../../../../../../design-system/components/Form";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { addCourseTaskToCourse } from "../mutations/add-course-task-to-course";
import { updateCourseTask } from "../mutations/update-course-task";
import { SaveCancelButtons } from "../../../../../../../components/SaveCancelButtons";
import { MarkdownEditor } from "../../../../../../../components/MarkdownEditor";
import { useIsOffline } from "../../../../../../../hooks/use-online-status";

type Props = {
  courseId: string;
  task?: CourseTaskDetailsFragment;
};

export const AddEditCourseTaskForm: React.FC<Props> = ({ task, courseId }) => {
  const isOffline = useIsOffline();
  const [title, setTitle] = useTextFieldInput(task?.title ?? "");
  const [description, setDescription] = useState(task?.description ?? "");

  const [addTask, { loading: addTaskLoading }] = useMutation(addCourseTaskToCourse, {
    variables: { input: { courseId, title, description } },
  });
  const [editTask, { loading: editTaskLoading }] = useMutation(updateCourseTask, {
    variables: { input: { courseId, title, description, taskId: task?.id ?? "" } },
  });
  const isSubmitting = addTaskLoading || editTaskLoading;

  const theme = useTheme();
  const themeSpacing2 = theme.spacing(2);
  const navigate = useNavigate();
  const loading = addTaskLoading;

  const onSubmit = async () => {
    if (isSubmitting) {
      return;
    }

    if (task) {
      const response = await editTask();
      const result = response.data?.updateCourseTask;
      if (result && result.success && result.course) {
        return navigate(-1);
      }

      const message = result?.message ?? "An error occurred while saving the task.";
      alert(message);
      console.error(message);
    } else {
      if (!title) {
        // TODO: show required fields
        return;
      }

      const response = await addTask();
      const result = response.data?.addCourseTaskToCourse;
      if (result && result.success && result.course) {
        return navigate(-1);
      }

      const message = result?.message ?? "An error occurred while saving the task.";
      alert(message);
      console.error(message);
    }
  };

  return (
    <Box mt={themeSpacing2}>
      <Form onSubmit={onSubmit}>
        <Container maxWidth="sm">
          <Stack spacing={4}>
            <FormControl>
              <FormLabel required>Title</FormLabel>
              <Input required value={title} onChange={setTitle} />
            </FormControl>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <MarkdownEditor markdown={description} onChange={setDescription} />
            </FormControl>
          </Stack>
        </Container>
        <SaveCancelButtons
          onCancel={() => navigate(-1)}
          submitting={isSubmitting}
          disabled={isOffline || isSubmitting || loading}
          fixedToBottom
        />
      </Form>
    </Box>
  );
};
