import React from "react";
import SaveIcon from "@mui/icons-material/Save";
import { Box, useTheme } from "@mui/joy";

import { CancelButton } from "../design-system/components/CancelButton";
import { SubmitButton } from "../design-system/components/SubmitButton";
import { BoxProps } from "@mui/joy/Box";

type Props = {
  onCancel: () => void;
  disabled: boolean;
  fixedToBottom?: boolean;
  fullWidth?: boolean;
  submitting?: boolean;
};

export const SaveCancelButtons: React.FC<Props> = ({
  onCancel,
  disabled,
  fixedToBottom,
  fullWidth,
  submitting,
}) => {
  const theme = useTheme();
  const themeSpacing2 = theme.spacing(2);
  const fixedToBottomStyle = fixedToBottom
    ? {
        position: "fixed",
        bottom: themeSpacing2,
        right: themeSpacing2,
      }
    : {};
  const boxStyle: BoxProps["sx"] = fixedToBottomStyle;
  if (fullWidth) {
    boxStyle.display = "flex";
  }

  return (
    <Box sx={boxStyle}>
      <CancelButton fullWidth={fullWidth} size="lg" onClick={onCancel} disabled={submitting} />
      <SubmitButton
        fullWidth={fullWidth}
        size="lg"
        loadingPosition="start"
        startDecorator={<SaveIcon />}
        disabled={disabled}
        loading={submitting}
        sx={{ marginLeft: theme.spacing(2) }}
      >
        Save
      </SubmitButton>
    </Box>
  );
};
