import { Box, Grid } from "@mui/joy";
import React, { useState } from "react";
import { useSuspenseQuery } from "@apollo/client";
import { useSetPageTitle } from "../../../../contexts/app-view";
import { CurrentTask, MarkingTaskComplete } from "./types";
import { getAtAGlanceStudentsQuery } from "./queries/get-students";
import { StudentGlance } from "./StudentGlance";
import { CompleteTaskDialog } from "./CompleteTaskDialog";
import { ActiveCourseFragment } from "../../../../generated/gql/graphql";

export const Home = () => {
  useSetPageTitle();
  const { data } = useSuspenseQuery(getAtAGlanceStudentsQuery);
  const [markingTaskComplete, setMarkingTaskComplete] = useState<MarkingTaskComplete | null>(null);

  const onMarkCompleted = (course: ActiveCourseFragment, task: CurrentTask) => {
    setMarkingTaskComplete({ course, task });
  };

  const studentGlances = data.students?.edges?.map((edge) => {
    const student = edge?.node;
    if (!student) {
      return null;
    }

    const activeCourses = student.activeCourses;

    return (
      <Grid xl={4} md={6} xs={12} key={student.id}>
        <StudentGlance
          studentId={student.id}
          name={student.firstName}
          courses={activeCourses}
          onMarkCompleted={onMarkCompleted}
        />
      </Grid>
    );
  });

  return (
    <Box>
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        {studentGlances}
      </Grid>
      {markingTaskComplete?.task && (
        <CompleteTaskDialog
          course={markingTaskComplete.course}
          task={markingTaskComplete.task}
          onSubmit={() => setMarkingTaskComplete(null)}
          onCancel={() => setMarkingTaskComplete(null)}
        />
      )}
    </Box>
  );
};
