import { graphql } from "../../../../../../../generated/gql";

export const updateCourseTask = graphql(`
  mutation UpdateCourseTask($input: UpdateCourseTaskInput!) {
    updateCourseTask(input: $input) {
      success
      message
      course {
        ...CourseDetails
      }
    }
  }
`);
