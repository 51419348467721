import { FragmentType, graphql } from "../../../../../generated/gql";

export const activeCourseFragment = graphql(`
  fragment ActiveCourse on Course {
    id
    name
    description
    subjects {
      id
      name
    }
    currentTask {
      id
      title
      description
      index
    }
  }
`);

export type ActiveCourseFragmentMask = FragmentType<typeof activeCourseFragment>;

// TODO: this is hard-coded to 10
export const getAtAGlanceStudentsQuery = graphql(`
  query AtAGlanceStudents {
    students(first: 10) {
      edges {
        node {
          id
          firstName
          lastName
          activeCourses {
            ...ActiveCourse
          }
        }
      }
    }
  }
`);
