import { Duration as D } from "luxon";
import React from "react";
import { Typography } from "@mui/joy";

type Props = {
  duration: string;
};

export const Duration: React.FC<Props> = ({ duration }) => {
  const d = D.fromISO(duration);

  return <Typography>{d.toHuman({ unitDisplay: "short" })}</Typography>;
};
