import React, { useState } from "react";
import { useTextFieldInput } from "../../../../hooks/form-hooks";
import {
  Checkbox,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalDialog,
  Stack,
  Textarea,
  Typography,
} from "@mui/joy";
import { DurationInput } from "../../../../design-system/components/DurationInput";
import { CurrentTask } from "./types";
import {
  ActiveCourseFragment,
  CourseDetailsFragment,
  CourseTaskDetailsFragment,
} from "../../../../generated/gql/graphql";
import { SubjectPicker } from "../../../../components/SubjectPicker";
import { useMutation } from "@apollo/client";
import { toIso8601 } from "../../../../utils";
import { markTaskComplete } from "../students/courses/tasks/mutations/mark-task-complete";
import { SaveCancelButtons } from "../../../../components/SaveCancelButtons";
import { getAtAGlanceStudentsQuery } from "./queries/get-students";
import { CourseSubjects } from "../students/courses/components/CourseSubjects";
import { MarkdownEditor } from "../../../../components/MarkdownEditor";
import { DateTimePicker, StaticDateTimePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";

type Props = {
  course: ActiveCourseFragment | CourseDetailsFragment;
  task: CurrentTask | CourseTaskDetailsFragment;
  onCancel: () => void;
  onSubmit: () => void;
};

export const CompleteTaskDialog: React.FC<Props> = ({ task, onSubmit, onCancel, course }) => {
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [notes, setNotes] = useTextFieldInput("");
  const [isDefaultDateTime, setIsDefaultDateTime] = useState(true);
  const [dateCompleted, setDateCompleted] = useState<DateTime | null>(
    DateTime.fromJSDate(new Date()),
  );
  const [completeCourseTaskMutation, { loading: isSubmitting }] = useMutation(markTaskComplete, {
    refetchQueries: [getAtAGlanceStudentsQuery],
  });
  const handleCompletedDateChange = (date: DateTime | null) => {
    setDateCompleted(date);
    setIsDefaultDateTime(false);
  };

  return (
    <Modal open={true} onClose={onCancel}>
      <ModalDialog>
        <DialogTitle>Complete Task</DialogTitle>
        <form
          onSubmit={async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (!hours.trim() && !minutes.trim()) {
              alert("Duration is required.");
              return;
            }

            const duration = toIso8601(hours, minutes);

            const response = await completeCourseTaskMutation({
              variables: {
                input: {
                  courseId: course.id,
                  taskId: task.id,
                  duration,
                  notes: notes.trim() || null,
                  dateCompleted: isDefaultDateTime ? null : dateCompleted?.toISO() ?? undefined,
                },
              },
            });

            // TODO: handle errors
            if (response.data?.completeCourseTask.success) {
              onSubmit();
            } else {
              alert(response.data?.completeCourseTask.message ?? "Something bad happened.");
            }
          }}
        >
          <Stack spacing={2}>
            <Typography level="title-md">
              {course.name} - {task.title}
            </Typography>
            <FormControl>
              <FormLabel>Date Completed</FormLabel>
              <DateTimePicker
                disableFuture
                value={dateCompleted}
                onChange={handleCompletedDateChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Evaluation</FormLabel>
              <Textarea minRows={2} maxRows={4} onChange={setNotes} value={notes} />
            </FormControl>
            <DurationInput
              hours={hours}
              onChangeHours={setHours}
              minutes={minutes}
              onChangeMinutes={setMinutes}
            />
            <Divider />
            <SaveCancelButtons fullWidth onCancel={onCancel} disabled={isSubmitting} />
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
};
