import { AddEditCourseForm } from "./AddEditCourseForm";
import { useSetPageTitle } from "../../../../../../contexts/app-view";
import { useParams } from "react-router-dom";
import React from "react";

type Params = { studentId: string };

export const AddCourse: React.FC = () => {
  useSetPageTitle("Add Course");
  const params = useParams<Params>();
  const studentId = params.studentId;
  if (!studentId) {
    // TODO: something better
    return <div>Student Not Found</div>;
  }

  return <AddEditCourseForm studentId={studentId} />;
};
