import Home from "@mui/icons-material/Home";
import Planning from "@mui/icons-material/Schedule";
import Students from "@mui/icons-material/Groups";
import ActivityLog from "@mui/icons-material/BrowseGallery";

export const HomeIcon = Home;
export const PlanningIcon = Planning;
export const StudentsIcon = Students;

export const ActivityLogIcon = ActivityLog;
