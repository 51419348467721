import { Box, Chip } from "@mui/joy";
import React from "react";
import { subjectTagListColorPicker } from "../../../../../../utils";

type Props = {
  subjects: {
    id: string;
    name: string;
  }[];
};

export const CourseSubjects: React.FC<Props> = ({ subjects }) => {
  return (
    <Box>
      {subjects.map((s, idx) => (
        <Chip
          sx={{ marginRight: 1 }}
          // color={subjectTagListColorPicker(idx)}
          key={s.id}
        >
          {s.name}
        </Chip>
      ))}
    </Box>
  );
};
