import { graphql } from "../../../../../generated/gql";

export const activityLogQuery = graphql(`
  query GetActivityLog(
    $sort: [ActivitySort!]
    $studentIds: [ID!]
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    activities(
      sort: $sort
      first: $first
      after: $after
      last: $last
      before: $before
      studentIds: $studentIds
    ) {
      edges {
        cursor
        node {
          id
          title
          description
          dateCreated
          dateCompleted
          subjects {
            id
            name
          }
          students {
            id
            firstName
          }
          duration
          notes
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`);
