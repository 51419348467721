import { graphql } from "../../../../../../../generated/gql";

export const markTaskComplete = graphql(`
  mutation MarkTaskComplete($input: CompleteCourseTaskInput!) {
    completeCourseTask(input: $input) {
      success
      message
      course {
        ...CourseDetails
      }
    }
  }
`);
