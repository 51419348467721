import React from "react";
import { useSetPageTitle } from "../../../../contexts/app-view";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  Typography,
} from "@mui/joy";
import { useSuspenseQuery } from "@apollo/client";
import { edgeFilter } from "../../../../utils";
import { Link as RouterLink } from "react-router-dom";
import {
  getStudentPiggyBanksViewQuery,
  studentPiggyBanksFragment,
} from "./queries/students-piggy-banks-views.graphql";
import { Money } from "../../../../components/Money";
import { StudentPiggyBanksFragment } from "../../../../generated/gql/graphql";
import { toFragment } from "../../../../generated/gql";
import AddIcon from "@mui/icons-material/Add";

export const PiggyBanks: React.FC = () => {
  useSetPageTitle("Piggy Banks");
  const { data } = useSuspenseQuery(getStudentPiggyBanksViewQuery);

  const students = data.students.edges
    .filter(edgeFilter)
    .map((s) => toFragment(studentPiggyBanksFragment, s.node));

  return (
    <Box>
      <List>
        {students.map((student) => (
          <ListItem key={student.id} sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
            <Box sx={{ flex: 1, minHeight: "100px" }}>
              <Typography level="h2">{student.firstName}</Typography>
              <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                {!!student.piggyBanks.length && <PiggyBankList student={student} />}
                <Grid xl>
                  <Card sx={{ width: "150px", height: "150px" }} variant="soft">
                    <CardContent sx={{ alignItems: "center", justifyContent: "center" }}>
                      <Link
                        component={RouterLink}
                        startDecorator={<AddIcon />}
                        to={`add-for-student/${student.id}`}
                        textAlign="center"
                        overlay
                        aria-label={`add new piggy bank for ${student.firstName}`}
                        sx={{
                          textDecoration: "none",
                          "&:hover": {
                            textDecoration: "none",
                          },
                        }}
                      >
                        Add Piggy Bank
                      </Link>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

const PiggyBankList: React.FC<{ student: StudentPiggyBanksFragment }> = ({ student }) => {
  return student.piggyBanks.map((piggyBank) => (
    <Grid sm key={piggyBank.id}>
      <Card sx={{ width: "150px", height: "150px" }} variant="outlined">
        <CardContent sx={{ alignItems: "center", justifyContent: "center" }}>
          <Link
            component={RouterLink}
            to={`${piggyBank.id}`}
            overlay
            sx={{
              textDecoration: "none",
              "&:hover": {
                textDecoration: "none",
              },
            }}
          >
            {piggyBank.name}
          </Link>
          <Typography>
            <Money value={piggyBank.balance} />
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  ));
};
