import { Alert, Box, Container, Sheet, Stack, Typography, useTheme } from "@mui/joy";
import React from "react";
import { Form } from "../design-system/components/Form";
import { Image } from "../design-system/components/Image";
import logo from "../images/logo.png";

type Props = {
  title: string;
  formError?: string;
  loading?: boolean;
  onSubmit: () => void;
  children: JSX.Element | JSX.Element[];
};

const formSpacing = 2;

export const LoginSignUpForm: React.FC<Props> = ({
  title,
  formError,
  loading,
  onSubmit,
  children,
}) => {
  const theme = useTheme();

  return (
    <Container maxWidth="sm">
      <Sheet>
        <Box justifySelf="center" textAlign="center" p={theme.spacing(4)}>
          <Box>
            <Image src={logo} alt="Logo" width={128} height={128} />
          </Box>
          <Typography level="h3" mt={theme.spacing(4)}>
            {title}
          </Typography>
          {formError ? (
            <Box mb={formSpacing}>
              <Alert color="danger">{formError}</Alert>
            </Box>
          ) : null}
          <Form onSubmit={onSubmit}>
            <Stack spacing={theme.spacing(formSpacing)}>{children}</Stack>
          </Form>
        </Box>
      </Sheet>
    </Container>
  );
};
