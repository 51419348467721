import React from "react";
import { AddEditStudentForm } from "./AddEditStudentForm";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { getStudentDetailsQuery, studentDetailsFragment } from "../queries/get-student.graphql";
import { toFragment } from "../../../../../generated/gql";
import { useSetPageTitle } from "../../../../../contexts/app-view";

type Props = {};

type EditStudentParams = {
  studentId: string;
};

export const EditStudent: React.FC<Props> = ({}) => {
  const params = useParams<EditStudentParams>();
  const studentId = params.studentId ?? "";
  const { data, loading } = useQuery(getStudentDetailsQuery, {
    variables: { id: studentId },
    skip: !studentId,
  });
  const student = toFragment(studentDetailsFragment, data?.student);
  useSetPageTitle("Edit Student");

  if (!params.studentId) {
    // TODO: something better
    return <div>Student Not Found</div>;
  }

  if (loading) {
    return <div>Loading</div>;
  }

  if (!student) {
    return <div>Student Not Found</div>;
  }

  return <AddEditStudentForm student={student} />;
};
