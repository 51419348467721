import React from "react";
import { AddEditItem } from "./AddEditItem";
import { useParams } from "react-router-dom";
import { useSuspenseQuery } from "@apollo/client";
import { getItemQuery } from "../queries/get-item";

export const EditItem: React.FC = () => {
  const params = useParams<{ id: string }>();
  if (!params.id) {
    throw new Error("No item ID provided");
  }

  const { data } = useSuspenseQuery(getItemQuery, { variables: { id: params.id } });
  return <AddEditItem item={data.piggyBankItem} />;
};
